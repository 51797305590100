import { SelectChangeEvent } from "@mui/material";
import {
  BaseEntityDropdown,
  builInvestmentVehicleIdForUI,
  ClientSelectLabels,
  Entity,
  EntityDropdownStyle,
  EntitySelectLabels,
  IClientDto,
  IDropdownItemData,
  IInvestmentEntityDropdownItem,
  isSomething,
  setSelectedEntity,
} from "common";
import { getEntityDropdownItems } from "common/src/utils/investmentEntityDropdownUtils";
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { InvestorUIStore } from "../../../../redux/store";
import styles from "./EntityDropdown.module.scss";

interface IEntityDropdownProps {
  clients: IClientDto[];
  clientsOnly?: boolean;
  styleVariant?: EntityDropdownStyle;
}
export const EntityDropdown = (props: IEntityDropdownProps) => {
  const {
    clients,
    clientsOnly = false,
    styleVariant = EntityDropdownStyle.DEFAULT,
  } = props;

  const { selectedEntity } = useSelector(
    (state: InvestorUIStore) => state.viewData
  );

  const activeInvestmentEntityDropdowmItem = useMemo(() => {
    if (isSomething(selectedEntity)) {
      const clientId = selectedEntity.value.clientId;
      const investmentVehicleId = selectedEntity.value.investmentVehicleId;

      return investmentVehicleId === undefined || clientsOnly
        ? clientId
        : builInvestmentVehicleIdForUI(clientId, investmentVehicleId);
    }
    return "";
  }, [selectedEntity, clientsOnly]);

  const dispatch = useDispatch();

  // Calculate dropdown items using useMemo for performance optimization
  const entityDropdownItems = useMemo(() => {
    if (clients.length === 0) {
      return [];
    }
    return getEntityDropdownItems([...clients], clientsOnly);
  }, [clientsOnly, clients]);

  /**
   * Sets the seleted investment entity menu item.
   * @param event A select change event
   */
  const onEntityChange = (event: SelectChangeEvent) => {
    const [clientId, investmentVehicleId] = event.target.value.split(":");

    const selectedClient: IClientDto | undefined = clients.find(
      (client: IClientDto) => client.id === clientId
    );

    if (selectedClient) {
      const selectedEntity: Entity = {
        clientId: clientId,
        investmentVehicleId: investmentVehicleId,
        mdmClientId: selectedClient.mdmOId,
      };
      dispatch(setSelectedEntity(selectedEntity));
    }
  };

  const getMenuItemTextClass = (
    investmentEntityDropdownItem: IInvestmentEntityDropdownItem
  ): string => {
    if (investmentEntityDropdownItem.isClient) {
      return investmentEntityDropdownItem.id ===
        activeInvestmentEntityDropdowmItem
        ? styles.selectedClientText
        : styles.clientText;
    } else {
      return investmentEntityDropdownItem.id ===
        activeInvestmentEntityDropdowmItem
        ? styles.selectedInvestmentVehicleText
        : styles.investmentVehicleText;
    }
  };

  const getMenuItemData = (
    item: IInvestmentEntityDropdownItem
  ): IDropdownItemData => {
    const label = item.displayName;
    const classes = getMenuItemTextClass(item);
    return { label, classes };
  };

  const getActiveItemLabel = (id: string): string => {
    const foundDropdownItem = entityDropdownItems.find(
      (dropdownItem) => dropdownItem.id == id
    );
    const label = foundDropdownItem?.activeDisplayName ?? "-";
    return label;
  };

  return (
    <BaseEntityDropdown
      items={entityDropdownItems}
      getActiveItemLabel={getActiveItemLabel}
      getMenuItemData={getMenuItemData}
      onSelectionChange={onEntityChange}
      selectedValue={activeInvestmentEntityDropdowmItem}
      subheaderLabel={
        clientsOnly
          ? ClientSelectLabels.DEFAULT_TEXT
          : EntitySelectLabels.DEFAULT_TEXT
      }
      styleVariant={styleVariant}
    />
  );
};
