import {
  Dashboard,
  EntityDropdownStyle,
  EnvironmentResolver,
  isSomething,
} from "common";
import React from "react";
import { useSelector } from "react-redux";

import { SharedEntitySelector } from "../../common/components/SharedEntitySelector/SharedEntitySelector";
import { InvestorUIStore } from "../../redux/store";

const DashboardWrapper = () => {
  const entitlements = useSelector(
    (store: InvestorUIStore) => store.entitlements
  );

  return (
    <Dashboard
      hasViewInternalInvestments={isSomething(
        entitlements.viewInternalInvestmentDataPermittedEntities
      )}
      hasViewStockEntitlement={entitlements.hasViewStockEntitlement}
      hasViewEquity={entitlements.viewEquityEntitlements.length > 0}
      hasViewRestrictedEquity={
        EnvironmentResolver.ENV.RESTRICTED_EQUITY_FEATURE_FLAG ||
        entitlements.hasViewRestrictedEquityEntitlement
      }
      hasViewV2DashboardEquityCardEntitlement={
        entitlements.hasViewV2DashboardEquityCardEntitlement
      }
      userWelcomeComponent={
        <SharedEntitySelector
          clientsOnly={true}
          styleVariant={EntityDropdownStyle.DASHBOARD}
        />
      }
    />
  );
};

export default DashboardWrapper;
