import {
  AuthenticatedPage,
  EnvironmentResolver,
  isInProgress,
  LoadingIndicator,
} from "common";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { UIErrorBoundary } from "../../../common/components/UIErrorBoundary/UIErrorBoundary";
import { Pages } from "../../../constants/Pages/Pages";
import { InvestorUIStore } from "../../../redux/store";
import { UserAgreements } from "../../UserAgreements/UserAgreements";
import { SingleDocumentDownloadPage } from "./SingleDocumentDownloadPage";

export const SingleDocumentDownloadPageWrapper = () => {
  const { userAgreements, userAgreementsLoadStatus } = useSelector(
    (store: InvestorUIStore) => store.userAgreementData
  );

  const pageLoadStatus = useSelector(
    (state: InvestorUIStore) => state.viewData.pageLoadStatus
  );

  const { search, pathname } = useLocation();

  const showUserAgreements: boolean = useMemo(() => {
    return (
      EnvironmentResolver.ENV.SHOW_USER_AGREEMENTS_FEATURE_FLAG &&
      userAgreements.filter(
        (agreement) =>
          (agreement.isMandatory && agreement.hasAgreed !== true) ||
          agreement.hasAgreed === null
      ).length > 0
    );
  }, [userAgreements]);

  return isInProgress(userAgreementsLoadStatus) ? (
    <LoadingIndicator />
  ) : showUserAgreements ? (
    <UIErrorBoundary page={Pages.UserAgreements}>
      <AuthenticatedPage
        pageElement={() => (
          <UserAgreements redirectLink={`${pathname}${search}`} />
        )}
        pageLoadStatus={pageLoadStatus}
        hideFooter={false}
      />
    </UIErrorBoundary>
  ) : (
    <UIErrorBoundary page={Pages.Documents}>
      <AuthenticatedPage
        pageElement={SingleDocumentDownloadPage}
        pageLoadStatus={pageLoadStatus}
        hideFooter={false}
      />
    </UIErrorBoundary>
  );
};
