import { IElectCardGridTooltips } from "../types/electionDataTypes";
import { isSomething } from "../types/typeGuards";
import { Optional } from "../types/typeUtils";
import {
  formatDateMMMDDYYYY,
  formatDateMMMDDYYYYOrNull,
  get2DPNumberFormattedWithZeroDefault,
  getCurrencyFormattedValueWithZeroDefault,
  getCurrencyFormattedValueWithZeroDefaultForOptional,
  getFormattedDateMMMDD,
  getFormattedDateTimeOnMDYOrNA,
  getFormattedLocalTime,
} from "../utils/formatters";
import { StockSymbol } from "./enums";

export const SectionHeader = {
  ANNUAL_COMMITMENTS: "Annual Side-by-Side Optional Commitments",
  ACTIVE_AWARD_DETAILS: "Active Award Details",
  BXWEALTH: "BXWealth",
  BXWEALTH_ADMIN: "BXWealth Admin",
  BANK_ACCOUNTS: "Bank Accounts",
  BANK_ACCOUNTS_TITLE: "Add or Edit Bank Accounts",
  ASSIGN_BANK_ACCOUNTS: "Assign Bank Accounts",
  BANK_ACCOUNTS_DESCRIPTION:
    "If you add or edit new bank account information you must submit it for Treasury review. Once submitted, your bank accounts will undergo the Treasury approval process. While under review, you will be unable to add or edit information on this page.",
  ASSIGN_BANK_ACCOUNTS_DESCRIPTION:
    "Only bank accounts managed by SIG are editable on BXWealth. Please reach out to BXWealth.Support@Blackstone.com for instructions on how to update your bank accounts for other Blackstone managed funds.",
  NEW_BANK_ACCOUNT: "New Bank Account",
  CARRY: "Carry",
  CARRY_DATA: "Carry Data",
  CARRIED_INTEREST_OVERVIEW: "Carried Interest Overview",
  CASH_FLOW: "Cash Flow",
  CASH_FLOW_OVERVIEW: "Cash Flow Overview",
  CLIENT_DATA_PAGE: "Client Data",
  COMMITMENTS: "Commitments",
  DASHBOARD: "Dashboard",
  DASHBOARD_DETAILS: "Dashboard Details",
  DISTRIBUTIONS: "Distributions",
  DOCUMENTS: "Documents",
  ELECTIONS: "SBS Elections",
  ELECTION_ADMIN_PAGE: "SBS Election Admin",
  ELECTOR_VIEW: "Elector View",
  EQUITY: "Equity",
  FINANCING: "Financing",
  FINANCING_BALANCE: "Financing Balance",
  FORECASTED_CAPITAL_CALLS: "Forecasted Future Capital Calls",
  HOLDBACK_INVESTED_CAPITAL: "Holdback Invested Capital",
  INVESTMENTS: "Investments",
  INVESTMENT_BREAKDOWN: "Investment Breakdown",
  INVESTMENTS_AND_CARRY: "Investments and Carry",
  INVESTMENTS_AND_CARRY_DETAILS: "Investments and Carry Details",
  INVESTMENTS_AND_CARRY_OVERVIEW: "Investments and Carry Overview",
  CLIENT_DATA: "Landing Page",
  LIFE_OF_FUNDS: "Life of Fund Optional Commitments",
  LOAN_BALANCE: "Loan Balance",
  LOANS: "Loans",
  ELECTION_EDITOR_PAGE: "Election Editor",
  OVERVIEW: "Overview",
  OVERVIEW_DATA: "Overview Data",
  PERFORMANCE_DETAILS: "Performance Details",
  PORTFOLIO_BALANCE: "Portfolio Balance",
  PORTFOLIO_PERFORMANCE: "Portfolio Performance",
  REALIZED_PROCEEDS_OVERVIEW: "Realized Proceeds Overview",
  REVIEW: "Review",
  SEG_TRUST: "Segregated Trust",
  SEG_TRUST_BALANCE: "Segregated Trust Balance",
  SBS_ELECTION_ADMIN_PAGE: "SBS Election Admin",
  UNREALIZED_CARRY: "Unrealized Carry",
  UNREALIZED_INVESTMENTS: "Unrealized Investments",
  UNREALIZED_VALUE: "Unrealized Value",
  UNREALIZED_VALUE_OVERVIEW: "Unrealized Value Overview",
  UNVESTED_EQUITY_OVERVIEW: "Unvested Equity Overview",
  UPCOMING_VESTING_EVENTS: "Upcoming Vesting Events",
  USER_PROFILE: "USER PROFILE",
  EQUITY_VALUE: "Equity Value",
  ELECTION_RESULTS: "Election Results",
  ELECTION_EDITOR: "Election Editor",
  EQUITY_OVERVIEW: "Equity Overview",
  USER_AGREEMENTS: "User Agreements",
};

export const SummaryLabel = {
  TOTAL: "Total",
  UNREALIZED_CARRY: "Unrealized Carry",
  UNREALIZED_INVESTMENTS: "Unrealized Investments",
  UNVESTED_EQUITY: "Estimated Equity Plan Value",
  REALIZED_PROCEEDS: "Realized Proceeds",
  CAPITAL_INVESTED: "Capital Invested",
  NET_CASH_FLOW: "Net Cash Flow",
};

export const OverviewLabel = {
  CARRY: "Carry",
  CARRY_AND_INCENTIVE: "Carry and Incentive Fees",
  UNREALIZED_VALUE: "Unrealized Value",
  REALIZED_PROCEEDS: "Realized Proceeds",
  REMAINING_INVESTMENT: "Remaining Investment",
  TOTAL_UNREALIZED_VALUE: "Total Unrealized Value",
  TOTAL_REALIZED_PROCEEDS: "Total Realized Proceeds",
  OPTIONAL_AND_MANDATORY: "Optional and Mandatory Investments",
  TOTAL: "TOTAL",
  RETURN_OF_CAPITAL: "Return of Capital",
  GAIN_LOSS: "Gain/Loss",
  LOANS: "Loans",
  NET_UNREALIZED_VALUE: "Net Unrealized Value",
};

export const OverviewTooltip = {
  CARRY_AND_INCENTIVE_FEES:
    "Realized performance fee compensation received through distributions.",
  RETURN_OF_CAPITAL:
    "Capital Invested that has been returned through distributions.",
  REALIZED_GAIN_LOSS:
    "Net profit/loss for investments received through distributions. Excludes withholdings, such as tax withholdings, loan withholdings, and expenses.",
  UNREALIZED_GAIN_LOSS:
    "Unrealized appreciation/depreciation on remaining investment based on the quarter end valuations.",
  TOTAL_REALIZED_PROCEEDS:
    "Realized proceeds received through distributions. Excludes withholdings, such as carry holdback, tax withholdings, loan withholdings, and expenses.",
  CARRY:
    "Unrealized value of carry compensation based on the quarter end valuations.",
  REMAINING_INVESTMENT:
    "Capital invested less any ROC (return of capital) already received through distributions.",
  LOANS: "Includes Firm, Pending Third-Party, and Third-Party loans.",
  UNREALIZED_OPTIONAL_AND_MADATORY:
    "Capital investments made through the SBS and carry programs.",
};

export const InvestmentHistoryLabel = {
  REALIZED_PROCEEDS: "Realized Proceeds",
  REALIZED_CARRY: "Realized Carry",
  REALIZED_CARRY_INCENTIVE: "Realized Carry and Incentive Fees",
  UNREALIZED_CARRY: "Unrealized Carry",
  CARRY: "Carry",
  REALIZED_INVESTMENT_INCOME_LOSS: "Realized Investment Income / Loss",
  REALIZED_ROC: "Realized ROC",
  RETURN_OF_CAPITAL: "Return of Capital",
  REALIZED_RETURN_OF_CAPITAL: "Realized Return of Capital",
  UNREALIZED_INVESTMENT_INCOME_LOSS: "Unrealized Investment Income / Loss",
  UNREALIZED_COST: "Unrealized Cost",
  UNREALIZED_REMAINING_INVESTMENT: "Unrealized Remaining Investment",
  UNREALIZED_VALUE: "Unrealized Value",
  OPTIONAL_MANDATORY_INVESTMENTS: "Optional and Mandatory Investments",
  UNREALIZED_VALUE_CARRY: "Unrealized Value – Carry",
  UNREALIZED_VALUE_INVESTMENTS: "Unrealized Value – Investments",
  NET_CASH_FLOW: "Net Cash Flow",
  BXWEALTH: "BXWealth",
  UNREALIZED: "Unrealized",
  REALIZED: "Realized",
  CAPITAL_INVESTED: "Capital Invested",
  TOTAL_UNREALIZED_VALUE: "Total Unrealized Value",
  REALIZED_VALUE: "Realized Value",
  REALIZED_CARRY_INVESTMENT_FEES: "Carry and Incentive Fees",
  INVESTMENT_GAIN_LOSS: "Gain/Loss",
  UNREALIZED_GAIN_LOSS: "Unrealized Gain/Loss",
  REALIZED_GAIN_LOSS: "Realized Gain/Loss",
  REMAINING_INVESTMENT: "Remaining Investment",
};

export const ForecastedCapCallsLabel = {
  STRATEGY: "Strategy",
};

export const getRealizedProceedsOverviewTooltip = (
  realizedDate: Date,
  earliestDate: Date
): string => {
  return `Realized Proceeds is as of ${formatDateMMMDDYYYYOrNull(
    realizedDate
  )} (updated daily). Data is available beginning ${formatDateMMMDDYYYYOrNull(
    earliestDate
  )}.`;
};

export const getUnrealizedValueOverviewTooltip = (
  unrealizedDate: Date
): string => {
  return `Unrealized Value based on quarter end valuations as of ${formatDateMMMDDYYYYOrNull(
    unrealizedDate
  )}`;
};

export const getCarryCardFooterFirstLine = (
  realizedDate: Date,
  firstDayOfQuaterDate: Date
): string => {
  return `Realized Value is as of ${formatDateMMMDDYYYYOrNull(
    realizedDate
  )} (updated daily). Data is available beginning ${formatDateMMMDDYYYYOrNull(
    firstDayOfQuaterDate
  )}. `;
};

export const getCarryCardFooterSecondLine = (unrealizedDate: Date): string => {
  return `Unrealized Value based on quarter end valuations as of ${formatDateMMMDDYYYYOrNull(
    unrealizedDate
  )}.`;
};

export const getUnrealizedRealizedAsOfLine = (
  unrealizedDate: Optional<Date>,
  realizedDate: Optional<Date>
): string => {
  let subHeader = "";
  if (isSomething(unrealizedDate)) {
    subHeader += `Unrealized value as of ${formatDateMMMDDYYYYOrNull(
      unrealizedDate.value
    )}. `;
  }
  if (isSomething(realizedDate)) {
    subHeader += `Realized value as of ${formatDateMMMDDYYYYOrNull(
      realizedDate.value
    )}.`;
  }
  return subHeader;
};

export const getAsOfLine = (date: Date): string => {
  return `As of ${formatDateMMMDDYYYYOrNull(date)}`;
};

export const getDateRangeHeader = (earliestDate: Date, latestDate: Date) => {
  return `${formatDateMMMDDYYYYOrNull(
    earliestDate
  )} - ${formatDateMMMDDYYYYOrNull(latestDate)}`;
};

export const getRealizedFooterLine = (firstDayOfQuaterDate: Date): string => {
  return `Realized data is available beginning ${formatDateMMMDDYYYYOrNull(
    firstDayOfQuaterDate
  )}.`;
};

export const getSegregatedTrustBalanceFooterSecondLine = (
  asOfDate: Date
): string => {
  return `Values based on quarter end valuations as of ${formatDateMMMDDYYYYOrNull(
    asOfDate
  )}.`;
};

export const getSummaryCashFlowFooter = (asOfDate: Date): string => {
  return `Realized Proceeds and Capital Invested data is available beginning  ${formatDateMMMDDYYYYOrNull(
    asOfDate
  )}.`;
};

export const getCashFlowFooter = (date: Date): string => {
  return `Realized Proceeds and Capital Invested data is available beginning ${formatDateMMMDDYYYYOrNull(
    date
  )}.`;
};

export const getPortfolioBalanceFooter = (date: Date): string => {
  return `Total Unrealized Value and Remaining Capital Invested data is available beginning ${formatDateMMMDDYYYYOrNull(
    date
  )}.`;
};

export const getPortfolioPerformanceFooter = (minDate: Date): string => {
  return `Realized Value and Unrealized Value data is available beginning ${formatDateMMMDDYYYYOrNull(
    minDate
  )}.`;
};

export const DashboardLabel = {
  Equity: {
    STOCK_PRICE_TOOLTIP: (asOfDate?: Date): string => {
      const date = asOfDate ? asOfDate : new Date();
      return `As of ${getFormattedDateMMMDD(date)} at ${getFormattedLocalTime(
        date
      )}`;
    },
    FOOTER:
      "Chart data only represents vesting events for unvested units. Values are calculated using the displayed stock price.",
  },
  Loans: {
    FOOTER: (asOfDate: Date) =>
      `Financing data is as of ${formatDateMMMDDYYYYOrNull(
        asOfDate
      )} (updated daily).`,
  },
};

export const LoanBalanceLabel = {
  LOAN_OVERVIEW: "Loans Overview",
  FIRM_LOAN: "Firm Loan",
  THIRD_PARTY_LOAN: "Third-Party Loan",
  PENDING_THIRD_PARTY_LOAN: "Pending Third-Party Loan",
  TOTAL_LOAN_BALANCE: "Total Loan Balance",
};

export const LoanBalanceTooltip = {
  FIRM_LOAN:
    "Financing provided by BX from the investment funding date until the date of the capital call payment.",
  THIRD_PARTY_LOAN: "Outstanding loan provided by third-party lender.",
  PENDING_THIRD_PARTY_LOAN:
    "Financing amounts, related to capital calls, expected to be drawn from third-party facility.",
  AVAILABLE_LOAN_CAPACITY:
    "Remaining loan capacity available for future capital calls. Equals Loan limit less Third-Party Loan, and Pending Third-Party Loan.",
};

export const ThirdPartyFinancingLabel = {
  THIRD_PARTY_LOAN: "Third-Party Loan",
  PENDING_LOAN: "Pending Loan",
  AVAILABLE_LOAN_CAPACITY: "Available Loan Capacity",
  THIRD_PARTY_LOANS: "Third-Party Loans",
  LOAN_LIMIT: "Loan Limit",
  PENDING_THIRD_PARTY_LOAN: "Pending Third-Party Loan",
  THIRD_PARTY: "Third-Party",
  LOAN_UTILIZATION: "Loan Utilization",
};

export const CarryLabel = {
  FUND: "Fund",
  FUNDID: "Fund Id",
  UNREALIZED_CARRY: "Unrealized Carry",
  REALIZED_CARRY_AND_INCENTIVE_FEES: "Realized Carry and Incentive Fees",
  CARRIED_INTEREST_OVERVIEW: "Carried Interest Overview",
  EXCLUDE_NEGATIVE_FUNDS:
    "Pie chart excludes funds with negative unrealized carry, table values include negative carry.",
};

export const SegregatedTrustTableLabel = {
  SEG_TRUST_BALANCE: "Segregated Trust Balance",
  SEG_TRUST: "Segregated Trust",
  BALANCE: "Balance",
  TOTAL_HOLDBACK_VALUE: "Total Holdback Value",
  TOTAL_HOLDBACK: "Total Holdback",
  HOLDBACK_INVESTED: "Holdback Invested",
  HOLDBACK_CAPITAL_INVESTED: "Holdback Capital Invested",
  HOLDBACK_CASH: "Cash",
  TRANCHE: "Tranche",
  HOLDBACK_OVERVIEW: "Holdback Overview",
  TOTAL_COLLATERAL_VALUE: "Total Collateral Value",
  TOTAL_COLLATERAL: "Total Collateral",
  REQUIRED_COLLATERALIZATION: "Required Collateralization",
  OVER_UNDER_COLLATERALIZATION: "Over/(Under) Collateralization",
  OVER_UNDER_COLLAT: "Over/Under Collateralization",
  FOOTER:
    "Total value is inclusive of excess collateralization held in funds with holdback. Data is available on a quarter lag based on best estimates and values that have been pledged. Please refer to your business statements for more up-to-date data.",
  FOOTER_FIRST_LINE:
    "Required collateralization must be at least 120% holdback invested.",
  ADMIN_FOOTER:
    "Data is available on a quarter lag based on best estimates and values that have been pledged.",
};

export const UserGridLabel = {
  NAME: "Name",
  EMPLOYEE_ID: "Employee ID",
  DEPARTMENT: "Department",
};

export const PerformanceDetailsLabel = {
  BUSINESS_UNIT: "Business Unit",
  FUND: "Fund",
  MDM_FUND_ID: "Fund Id",
  ELECTION_PERIOD: "Election Period",
  INVESTMENT: "Investment",
  INVESTMENT_DATE: "Investment Date",
  INVESTMENT_TYPE: "Investment Type",
  CAPITAL_INVESTED: "Capital Invested",
  REALIZED_PROCEEDS: "Realized Proceeds",
  RETURN_OF_CAPITAL: "Return of Capital",
  GAIN_LOSS: "Gain/Loss",
  CARRY_AND_INCENTIVE_FEES: "Carry and Incentive Fees",
  REALIZED_TOTAL: "Realized Total",
  UNREALIZED_VALUE: "Unrealized Value",
  REMAINING_INVESTMENT: "Remaining Investment",
  CARRY: "Carry",
  UNREALIZED_TOTAL: "Unrealized Total",
  TOTAL: "Total",
  getCapitalInvestedHeader: (latestDate: Date, earliestDate: Date) =>
    `${formatDateMMMDDYYYYOrNull(earliestDate)} - ${formatDateMMMDDYYYYOrNull(
      latestDate
    )}`,
  getRealizedProceedsHeader: (latestDate: Date, earliestDate: Date) =>
    `${formatDateMMMDDYYYYOrNull(earliestDate)} - ${formatDateMMMDDYYYYOrNull(
      latestDate
    )}`,
  getUnrealizedValueHeader: (latestDate: Date | undefined) =>
    `As of ${formatDateMMMDDYYYYOrNull(latestDate)}`,
};

export const stockPriceTooltip = (asOfDate?: Date): string[] => {
  const date = asOfDate ? asOfDate : new Date();
  return [
    `As of ${getFormattedDateMMMDD(
      asOfDate ?? new Date()
    )}, ${asOfDate?.getFullYear()} at ${getFormattedLocalTime(date)}`,
  ];
};

export const stockChartTooltip = (
  value?: number,
  asOfDate?: Date
): string[] => {
  const date = asOfDate ? asOfDate : new Date();
  return [
    `${get2DPNumberFormattedWithZeroDefault(value)} USD ${formatDateMMMDDYYYY(
      date
    )} at ${getFormattedLocalTime(date)}`,
  ];
};

export const EquityLabel = {
  OVERVIEW: "Total Equity Overview",
  TOTAL_TOTAL_STOCK_VALUE: (symbol: StockSymbol) => `Total ${symbol} Value`,
  TOTAL_STOCK_UNITS: (symbol: StockSymbol) => `Number of ${symbol} Units`,
  STOCK_PRICE: "Stock Price",
  STOCK_NYSE: (symbol: StockSymbol) => `${symbol} | NYSE`,
  NEXT_VESTING_DATE: "Next Vesting Date",
  YTD_DISTRIBUTIONS: "YTD Distributions",
  UNVESTED_EQUITY_OVERVIEW: "Estimated Equity Plan Value",
  TOTAL_UNVESTED_VALUE: "Total Unvested Value",
  TOTAL_VESTED_RESTRICTED_VALUE: "Total Vested Restricted Value",
  NUMBER_OF_UNVESTED_UNITS: "Number of Unvested Units",
  NUMBER_OF_VESTED_RESTRICTED_UNITS: "Number of Unvested Units",
  FOOTER_FIRST_LINE:
    "To view and take action on vested and delivered shares of Blackstone common stock, please visit ",
  MERRILL_LYNCH_URL: "https://www.ml.com",
  MERRILL_LYNCH_BENEFITS_URL: "https://www.benefits.ml.com",
  ACTIVE_AWARDS_DETAILS: "Active Award Details",
  AWARD: "Award",
  UNITS_UNVESTED: "Units Unvested",
  UNITS_VESTED: "Units Vested",
  UNVESTED_VALUE: "Unvested Value",
  ACTIVE_AWARDS_DETAILS_FOOTER: "Total",
  MERYLL_LYNCH: "Merrill Lynch Benefits Online",
  VIEW_ON_MERRILL_LYNCH: "View on Merrill Lynch Benefits Online",
  UNITS_GRANTED: "Units Granted",
  TOTAL: "Total",
  UNVESTED: "Unvested",
  VESTED_RESTRICTED: "Vested Restricted",
  VESTED_EXCHANGEABLE: "Vested Exchangeable",
  UNITS: "Units",
  VALUE: "Value",
};

export const EquityTooltip = {
  TOTAL_UNVESTED_VALUE:
    "Total Unvested Value is calculated based on the displayed stock price.",
  TOTAL_TOOLTIP_VESTED_RESTRICTED:
    "Total Vested Restricted Value is calculated based on the displayed stock price.",
  YTD_DISTRIBUTIONS:
    "Year-to-Date distributions received on undelivered units.",
  ACTIVE_AWARDS_DETAILS:
    "Awards that have one or more future vesting events remaining.",
  AS_OF: "As of",
  UNVESTED_VALUE_TOOLTIP: [
    "Units that have not yet met vesting requirements,",
    "multiplied by displayed stock price.",
  ],
  VESTED_RESTRICTED_VALUE_TOOLTIP: [
    "Units that have vested but are not yet available for delivery,",
    "multiplied by displayed stock price.",
  ],
  VESTED_EXCHANGEABLE_VALUE_TOOLTIP: [
    "Holdings units available to exchange for shares of common BX stock,",
    "multiplied by displayed stock price.",
  ],
};

export const UpcomingVestingEventsLabel = {
  VALUE: "Value ($)",
  FUTURE_VESTING_DATES: "Future Vesting Dates",
};

export const ActiveAwardDetailsFileLabel = {
  AWARD: "Award",
  SYMBOL: "Symbol",
  TICKER: "Ticker",
  VESTING_DATE: "Vesting Date",
  UNITS_VESTED: "Units Vested",
  UNITS_UNVESTED: "Units Unvested",
  UNVESTED_VALUE: "Unvested Value",
};

export const EquityOverviewWorkSheet = {
  UNIT_TYPE: "Unit Type",
  AS_OF: "As Of",
  AMOUNT: "Amount",
  VALUE_TYPE: "Value Type",
  VALUE: (date: string) => `Value ${date}`,
};

export const EquityOverviewUnitTypes = {
  UNVESTED: (symbol: string) => `Unvested ${symbol} Units`,
  VESTED_RESTRICTED: (symbol: string) => `Vested Restricted ${symbol} Units`,
  VESTED_EXCHANGEABLE: (symbol: string) =>
    `Vested Exchangeable ${symbol} Units`,
};

export const EquityOverviewValueTypes = {
  UNVESTED: (symbol: string) => `Unvested ${symbol} Value`,
  VESTED_RESTRICTED: (symbol: string) => `Vested Restricted ${symbol} Value`,
  VESTED_EXCHANGEABLE: (symbol: string) =>
    `Vested Exchangeable ${symbol} Value`,
};

export const VestingEventsRangeLabel = {
  ALL: "ALL",
  THREE_YEARS: "3Y",
};

export const VestingEventsNoDataLabel = {
  NO_VESTING_DATA: (symbol: StockSymbol) =>
    `No Vesting Event Data Available for ${symbol}`,
  CONTACT_TEXT:
    "If you have questions related to your equity, please reach out to ",
  EMAIL_ID: "BXEquity@Blackstone.com",
};

export const DistributionsLabel = {
  DIST_DATE: "Distribution Date",
  TICKER: "Ticker",
  UNIT_TYPE: "Unit Type",
  DIST_PER_UNIT: "Distribution Per Unit",
  UNITS_ENTITLED: "Units Entitled To Distribution",
  PAYMENT_AMOUNT: "Payment Amount",
};

export const DistributionsTooltip = {
  UNITS_ENTITLED: "Unvested and/or undelivered units.",
  PAYMENT_AMOUNT: "Payments are gross of tax withholdings.",
};

export const EquityDistributionsNoDataLabel = {
  CONTACT_TEXT:
    "If you have questions related to your equity, please reach out to ",
  EMAIL_ID: "BXEquity@Blackstone.com",
  NO_DISTRIBUTIONS_DATA: (symbol: StockSymbol) =>
    `No Distribution Data Available for ${symbol}`,
};

export const DocumentsLabel = {
  DOCUMENTS: "Documents",
  DOWNLOAD_DATE: "Download Status",
  DOCUMENT_TYPE: "Document Type",
  DOCUMENT_NAME: "Document Name",
  DOCUMENT_TYPE_CATEGORY: "Document Type Category",
  CATEGORY: "Category",
  PERIOD: "Period",
  FUND: "Fund",
  PUBLISHED: "Published",
  PUBLISHED_DATE: "Published Date",
  EFFECTIVE_DATE: "Effective Date",
  IV: "Investment Vehicle",
  PARTNERSHIP: "Partnership",
  PARTNERSHIP_EIN: "Partnership EIN",
  ESTIMATE_FINAL: "Estimate/Final",
  EST_FINAL: "Est./Final",
  JURISDICTION: "Jurisdiction",
  TAX_JURISDICTION: "Tax Jurisdiction",
  MANAGE_COLUMNS: "Manage Columns",
  APPLY: "Apply",
  RESET: "Reset",
  FILTERS: "Filters",
  RESET_FILTERS: "Reset Filters",
  APPLY_FILTERS: "Apply Filters",
  START_DATE: "Start Date",
  END_DATE: "End Date",
  MAX_DOWNLOAD_SIZE_WARNING:
    "Maximum download is 250 documents. Please unselect and try again.",
  APPLY_FILTER_WARNING: "Applying new filters will clear selected rows.",
  PLEASE_RESOLVE_ERRORS: "Please Resolve Errors",
  TYPE_TO_SEARCH: "Type to search",
  SEARCH_BY_NAME: "Search by Document Name, Type, or Period",
  SEARCH: (isMobile: boolean) =>
    isMobile ? DocumentsLabel.TYPE_TO_SEARCH : DocumentsLabel.SEARCH_BY_NAME,
  DOWNLOADED_TOOLTIP: (dateTime: Date) =>
    `Last downloaded ${formatDateMMMDDYYYY(
      dateTime
    )} at ${getFormattedLocalTime(dateTime)}`,
  HEADER_CHECKBOX_TOOLTIP: "Select all documents on this page",
  PREPARING_DOCUMENTS: "Your documents are being prepared.",
  ALL: "All",
  DOWNLOAD_STATUS_ICON_ID: "downloadStatusIcon",
  RETURN_TO_ALL_DOCUMENTS: "Return To All Documents",
  INACCESIBLE_DOCUMENT:
    "The document you are attempting to view is either no longer accessible or you do not have permission to view it.",
  PLEASE: "Please ",
  CLICK_HERE: "Click Here",
  TO_RETURN_TO_PLATFORM: " to return to the platform.",
  DOWNLOADING_YOUR_DOCUMENT: "Downloading your document...",
  IF_YOUR_DOCUMENT_DOES_NOT_DOWNLOAD:
    " if your document does not download automatically.",
  IF_YOU_EXPERIENCE_ISSUES: "If you experience issues, contact ",
  FOR_ASSISTANCE: " for assistance.",
};

export const ElectionsLabels = {
  AMOUNT: "Amount",
  ANTICIPATED_PRIOR_PERIODS_CAPITAL_CALLS:
    "Anticipated Prior Periods Capital Calls",
  ELECTIONS: "Elections",
  OVERVIEW: "Overview",
  ELIGIBILITY: "Eligibility",
  STRATEGIES: "Strategies",
  ELECT: "Elect",
  BANK_ACCOUNT: "Bank Account",
  REVIEW_AND_SIGN: "Review & Sign",
  BACK: "Back",
  BACK_TO_ELECTIONS: "Back to Elections",
  BACK_TO_ELECTOR_VIEW: "Back to Elector View",
  DEFINITION: "Definition",
  DEFINITIONS: "Definitions",
  ACCREDITED_INVESTOR: "Accredited Investor",
  QUALIFIED_PURCHASER: "Qualified Purchaser",
  CONFIRMATION: "Confirmation",
  US_SECURITIES_ACT: "U.S. Securities Act of 1933",
  INVESTMENT_COMPANY_ACT: "Investment Company Act of 1940",
  ACCREDITED_INVESTOR_DEFINITION: [
    "This definition refers to Rule 501(a) of the U.S. Securities Act of 1933, as amended.",
    "Rule 501(a)(5) - Any natural person whose individual net worth, or joint net worth with that person's spouse or spousal equivalent, exceeds $1,000,000. For purposes of this item," +
      ' "net worth" means the excess of total assets at fair market value, including automobiles and other personal property, provided that for purposes of calculating net worth:',
    "(i) The natural person's primary residence shall not be included as an asset;",
    "(ii) Indebtedness that is secured by such person's primary residence, up to the estimated fair market value of the primary residence at the time of the sale of securities (i.e., at the time of any purchase by the undersigned of interests in the ESC Partnership), shall not be included as a liability (except that if the amount of such indebtedness outstanding at the time of sale of securities exceeds the amount outstanding 60 days before such time, other than as a result of the acquisition of the primary residence, the amount of such excess shall be included as a liability); and",
    "(iii) Indebtedness that is secured by such person's primary residence in excess of the estimated fair market value of the primary residence at the time of the sale of securities shall be included as a liability.",
    "Rule 501(a)(6) - Any natural person who had an individual income in excess of $200,000 in each of the two most recent years or joint income with that person's spouse or spousal equivalent in excess of $300,000 in each of those years and has a reasonable expectation of reaching the same income level in the current year.",
    'Rule 501(j) - The term "spousal equivalent" shall mean a cohabitant occupying a relationship generally equivalent to that of a spouse.',
  ],
  QUALIFIED_PURCHASER_DEFINITION: [
    "This definition refers to the Investment Company Act of 1940, as amended. (i) Any natural person (including any person who holds a joint, community property, or other similar shared ownership interest in an issuer that is excepted under section 3(c)(7) with that person's qualified purchaser spouse) who owns not less than $5,000,000 in investments, as defined by the Commission;",
    "(ii) Any company that owns not less than $5,000,000 in investments and that is owned directly or indirectly by or for 2 or more natural persons who are related as siblings or spouse (including former spouses), or direct lineal descendants by birth or adoption, spouses of such persons, the estates of such persons, or foundations, charitable organizations, or trusts established by or for the benefit of such persons;",
    "(iii) Any trust that is not covered by clause (ii) and that was not formed for the specific purpose of acquiring the securities offered, as to which the trustee or other person authorized to make decisions with respect to the trust, and each settlor or other person who has contributed assets to the trust, is a person described in clause (i), (ii), or (iv); or",
    "(iv) Any person, acting for its own account or the accounts of other qualified purchasers, who in the aggregate owns and invests on a discretionary basis, not less than $25,000,000 in investments.",
  ],
  BY_SELECTING_LINE_STARTING:
    "By selecting I Agree below, you confirm your status as",
  ADDITIONALLY_LINE_STARTING: "Additionally, you confirm your status as",
  WITHIN_THE_MEANING_OF: "within the meaning of",
  RULE_501_OF_REGULATION_D_UNDER:
    'Rule 501(a)(5) or (6) of Regulation D ("Regulation D") under',
  AS_AMENDED_1940_LINE_ENDING: ', as amended (the "1940 Act").',
  AS_AMENDED_1933_LINE_ENDING: ', as amended (the "1933 Act").',
  NOT_ELIGIBLE_LINES:
    "Thank you for your response. As indicated in the SBS election documents, you must be an Accredited Investor in order to participate in the side-by-side program.",
  CANCEL: "Cancel",
  OK: "OK",
  I_AGREE: "I Agree",
  NEXT: "Next",
  RESET_ADMIN_WORKFLOW: "Reset Admin Workflow",
  SAVE: "Save",
  STATUS: "Status",
  ELECTION_DATE: "Election Date",
  ELECTION_NAME: "Election Name",
  INVESTMENT_VEHICLE: "Investment Vehicle",
  ELECTION_DECISION: "Election Decision",
  ELECTION_PROCESS: "Election Process",
  ELECTION: "Election",
  FOOTENOTE_LINE1:
    "1. Final election amount may be reduced due to fund oversubscription and can be viewed on the Commitments page.",
  FOOTENOTE_LINE2:
    "2. Only elections from January 1, 2013 onward will be available in this view.",
  TOTAL: "Total",
  STAGES_AND_DESCRIPTION: "Stages & Description",
  STRATEGY: "Strategy",
  STRATEGY_OVERVIEW: "Strategy Overview",
  MANDATORY_COMMITMENT: "Mandatory Commitment",
  OPTIONAL_ELECTION: "Optional Election",
  TOTAL_COMMITMENT: "Total Commitment",
  LEGAL_ATTESTATION: "Legal Attestation",
  LEGAL_ATTESTATION_HEADER_LINE:
    "Legal Attestation is required in order to continue the Election process.",
  LEGAL_ATTESTATION_CHECKBOX_LINE:
    "I attest to having read the legal terms above.",
  PRIOR_ELECTIONS: "Prior Elections",
  CURRENT_INVESTMENT_PORTFOLIO: "Current Investment Portfolio",
  RESIDENTIAL_INFORMATION: "Residential Information",
  COUNTRY_RESIDENT_TEXT:
    "Are you a Citizen, Resident, or Green Card Holder of the United States?",
  STATE_RESIDENT_TEXT: "Are you a New York State resident?",
  YES: "Yes",
  NO: "No",
  ELIGIBILITY_ATTESTATION: "Eligibility Attestation",
  ELIGIBLE_STATUSES: "Eligible Statuses",
  ACCREDITED_INVESTOR_STATUS: "Accredited Investor Status",
  QUALIFIED_PURCHASER_STATUS: "Qualified Purchaser Status",
  NOT_ELIGIBLE: "Not Eligible",
  ELIGIBILITY_CHECK_ALL_BOXES:
    "Please check all boxes that apply. You only need to meet one criteria below (in either section) to be eligible for the program - alternatively, you can check that none apply/you are not eligible to participate.",
  ELIGIBILITY_CHECKBOX: {
    ACCREDITED_INVESTOR: {
      INDIVIDUAL_NET_WORTH:
        "A natural person whose individual net worth, or joint net worth with that person's spouse or spousal equivalent, exceeds $1,000,000. For purposes of this item, “net worth” means the excess of total assets at fair market value, including automobiles and other personal property but excluding the value of the primary residence of the Investor (and including property owned by a spouse other than the primary residence of the spouse), over total liabilities. For this purpose, the amount of any mortgage or other indebtedness secured by an investor's primary residence should not be included as a “liability”, except to the extent (i) the fair market value of the residence is less than the amount of such mortgage or other indebtedness, or (ii) such indebtedness existing on the date of the acceptance of the investor's subscription for interests exceeds the indebtedness that existed 60 days preceding such date and such indebtedness was not as a result of the acquisition of the investor's primary residence.",
      INDIVIDUAL_INCOME:
        "A natural person who had an individual income in excess of $200,000 in each of the two most recent years or joint income with that person's spouse or spousal equivalent in excess of $300,000 in each of those years and has a reasonable expectation of reaching the same income level in the current year.",
      PROFESSIONAL:
        "A natural person holding in good standing one or more professional certifications or designations or credentials from an accredited educational institution that the Securities and Commission has designated as qualifying an individual for accredited investor status, which include holders of Series 7, Series 65, and Series 82 licenses from FINRA.",
    },
    QUALIFIED_PURCHASER: {
      BLACKSTONE_FUND:
        'A natural person (including any person who holds a joint, community property or other similar shared ownership interest in a Blackstone Fund with that person\'s qualified purchaser spouse) who owns not less than $5,000,000 in "investments".',
      COMPANY:
        "Any company that owns not less than $5,000,000 in investments and that is owned directly or indirectly by or for 2 or more natural persons who are related as siblings or spouse (including former spouses), or direct lineal descendants by birth or adoption, spouses of such persons, the estates of such persons, or foundations, charitable organizations, or trusts established by or for the benefit of such persons.",
      TRUST:
        "Any trust that is not covered by clause (ii) and that was not formed for the specific purpose of acquiring the securities offered, as to which the trustee or other person authorized to make decisions with respect to the trust, and each settlor or other person who has contributed assets to the trust, is a person described in clause (i), (ii), or (iv).",
      INDIVIDUAL_INVESTMENT:
        "Any person, acting for its own account or the accounts of other qualified purchasers, who in the aggregate owns and invests on a discretionary basis, not less than $25,000,000 in investments.",
    },
    NOT_ELIGIBLE: "None of the above checkbox options apply to me.",
  },
  REALLOCATION: "Reallocation",
  REALLOCATION_QUESTION:
    "If any of the strategies I elected are oversubscribed, reallocate my oversubscribed amount across the other elected strategies in the same proportion as my current election.",
  OPT_ELECTION_HEADER_LINES: (tooltips: IElectCardGridTooltips) => {
    const topLine =
      tooltips.mandatoryCommitmentYear > 0
        ? `${tooltips.mandatoryCommitmentYear} ${ElectionsLabels.OPTIONAL_ELECTION}`
        : ElectionsLabels.OPTIONAL_ELECTION;
    return [
      topLine,
      `Offer Amount: ${getCurrencyFormattedValueWithZeroDefaultForOptional(
        tooltips.offeredAmount
      )}`,
    ];
  },
  TOTAL_FORECASTED_INVESTMENT: "Total Forecasted Investment",
  PPM: "PPM",
  LIMITED_PARTNER_COMMITMENT: "Limited Partner Commitment",
  BLACKSTONE_COMMITMENT: "Blackstone Commitment",
  TOTAL_FUND_SIZE: "Total Fund Size",
  INVESTMENT_PERIOD_YEARS: "Investment Period Years",
  TOTAL_SUBJECT_TO_ELECTION: "Total Subject To Election",
  FORECASTED_CAPITAL_DEPLOYMENT_PERCENTAGE: "Forecasted Capital Deployment %",
  FORECASTED_CAPITAL_DEPLOYMENT: "Forecasted Capital Deployment",
  VIEW_STRATEGY_OVERVIEW_PDF: "View Strategy Overview PDF",
  CASH: "Cash",
  FINANCING_AT_PERCENT: (percent: number) => {
    return `Financing at ${percent}%`;
  },
  EXISTING_LOAN_BALANCE: "Existing Loan Balance",
  PROJECTED_LOAN_BALANCE: "Projected Loan Balance",
  LOAN_LIMIT: "Loan Limit",
  ENABLE: "Enable",
  DISABLE: "Disable",
  ENABLE_FINANCING_TOOLTIP: "Toggle to enable/disable financing",
  STRATEGY_MIN_ERROR_MESSAGE: (strategyMin: number) => {
    return `A minimum of ${getCurrencyFormattedValueWithZeroDefault(
      strategyMin
    )} is required for this strategy.`;
  },
  STRATEGY_MAX_ERROR_MESSAGE: (strategyMax: number) => {
    return `A maximum of ${getCurrencyFormattedValueWithZeroDefault(
      strategyMax
    )} is allowed for this strategy.`;
  },
  AGREE_AND_SUBMIT: "Agree & Submit",
  SIGN_AND_SUBMIT: "Sign & Submit",
  SIGNATURE_IS_REQUIRED:
    "Signature is required in order to complete the Election process. After submission, you can revise your election prior to the election deadline.",
  SIGN_HERE: "Sign Here",
  FINANCING: "Financing",
  PLEASE_COMPLETE_LEGAL_ATTESTATION:
    "Please complete legal attestation before proceeding.",
  PLEASE_COMPLETE_REQUIRED_FIELDS: "Please complete the required (*) fields.",
  FINANCING_OPTIONS: "Financing",
  FINANCING_OPTIONS_DOWNLOAD_BUTTON_TEXT: "View Financing PDF",
  NO_DEADLINE: "No Deadline",
  ADMIN_DISCLAIMER:
    "You are viewing an election experience that replicates what an elector would see. Current state does not reflect the elector's state and changes made in this view will have no impact on the investor's election.",
  REALLOCATION_CONFIRMATION:
    "You are confirming that any oversubscribed amount will not be reallocated and your total elected amount will be reduced accordingly.",
  OFFER_AMOUNT: "Offer Amount",
  ELECTION_PERIOD_AND_DEADLINE: "Election Period & Deadline",
  ELECTION_SUBMISSION_DEADLINE: "Election Submission Deadline",
  TAX_FORMS_DEADLINE: "Tax Forms Deadline",
  ANNUAL_ELECTION_PERIOD: "Annual Election Period",
  GET_STARTED: "Begin election process",
  CONTINUE: "Continue election process",
  REVIEW: "Review or revise election before deadline",
  SUBMITTED: "View submitted election",
  CLOSED: "No election submitted",
  DAYS_LEFT: (days: number) => `${days} days left`,
  DUE_AT: (dueDate: Date) => `Due: ${getFormattedLocalTime(dueDate)}`,
  SUBMISSION_DEADLINE: (dueDate: Date) =>
    `Submission Deadline: ${formatDateMMMDDYYYY(
      dueDate
    )}, ${getFormattedLocalTime(dueDate)}`,
  DEADLINE_PASSED: "Deadline Passed",
  ELECTION_TEAM: "Election Team",
  ELECTION_CONTACT_CARD_FOOTER: "If you have any questions, please email ",
  ELECTION_CONTACT_EMAIL: "SIG-PIM@Blackstone.com",
  INVALID_STRATEGY_AMOUNT:
    "You have entered invalid amounts for at least one strategy. Please adjust your elections.",
  INVALID_ELECTION_MINIMUM: (electionMin: number) => {
    return `A minimum of ${getCurrencyFormattedValueWithZeroDefault(
      electionMin
    )} total is required.`;
  },
  YOU_ARE_EXCEEDING_OFFER_AMOUNT:
    "You are exceeding your total offered amount. Please adjust your elections.",
  YOU_ARE_EXCEEDING_ADDITIONAL_LIMIT: (additionalRequestLimit: number) =>
    `You are exceeding your additional request limit of ${getCurrencyFormattedValueWithZeroDefault(
      additionalRequestLimit
    )}. Please adjust your elections.`,
  ENTER_AMOUNT: "Enter amount...",
  PLEASE_COMPLETE_ELECT_REALLOCATION:
    "Please complete reallocation before proceeding.",
  PLEASE_COMPLETE_SIGN_AND_SUBMIT:
    "Please complete Sign & Submit before proceeding.",
  UNABLE_TO_SAVE_LATEST:
    "Unable to save latest. Please try again shortly. If the problem persists, reach out to support.",
  ELECTION_LIST_TITLE: "Download Election Documents",
  ELECTION_LIST_NO_DOCUMENTS: "No documents available for download.",
  DOWNLOAD_ALL_DOCUMENTS: (docNumber: number) => `Download All (${docNumber})`,
  CONGRATULATIONS_YOUVE_SUBMITTED:
    "Congratulations, you've submitted the election!",
  SUBMISSION_CONFIRMATION_MESSAGE:
    "You have successfully completed your election. An e-mail confirmation will be sent to you for your records.",
  DOWNLOAD_ELECTION_AGREEMENT: "Download Election Agreement",
  RETURN_TO_ALL_ELECTIONS: "Return To All Elections",
  EXCEEDS_STRATEGY_MAXIMUM: "Exceeds Strategy Maximum",
  EXCEEDS_OFFERED_AMOUNT_AND_STRATEGY_MAXIMUM:
    "Exceeds Offer Amount & Strategy Maximum",
  EXCEEDS_OFFER_AMOUNT: "Exceeds Offer Amount",
  YOU_ARE_EXCEEDING: "You are exceeding your ",
  STRATEGY_MAXIMUM_AMOUNT: "strategy maximum amount.",
  TOTAL_OFFERED_AMOUNT: "total offered amount.",
  STRATEGY_MAXIMUM: "Strategy Maximum",
  ELECTED: "Elected",
  CLICK_CANCEL: "Click cancel to adjust your elections.",
  EXCEEDS_STRATEGY_WARNING_MESSAGE:
    "Please note that your commitment is subject to review and management approval.",
  EXCEEDS_OFFER_AMOUNT_MESSAGE:
    "Your election exceeds your offer amount. This additional requested commitment is subject to review and management approval.",
  EXCEEDS_LOAN_LIMIT: "Exceeds Loan Limit",
  EXCEEDS_LOAN_LIMIT_MESSAGE:
    "Your commitment exceeds your current loan limit. Any commitment over your loan limit will be 100% out-of-pocket cash. If you would like to increase your loan limit, please contact your third party lender.",
  LOAN_LIMIT_INCREMENT_MESSAGE:
    "If your loan limit increase is currently in progress, updates will not be reflected in the election portal.",
  IF_QUESTIONS_MESSAGE: "If you have any questions, please reach out to ",
  LOAN_LIMIT_CONTACT: "SIG-PIM@Blackstone.com",
  ELECTION_DOCUMENTS_CARD_HEADER: "Election Documents",
  ELECTION_DOCUMENTS_CARD_SUBTITLE:
    "The documents below are also available throughout the election process via the Document button at the top of the page.",
  ELECTION_ALLOCATION: "Election Allocation",
  ELECTION_ALLOCATION_HEADER_MESSAGE:
    "To auto-allocate your election pro-rata across all strategies based on forecasted capital deployment, enter your total elected amount below and click the Auto-Allocate button. Once allocated, you will have the ability to adjust each strategy’s election if needed. Alternatively, if you would like to choose your own elections, click the Continue to Manual Election button to proceed.",
  CONTINUE_TO_MANUAL_ELECTION: "Continue to Manual Election",
  AUTO_ALLOCATION_TOOL: "Auto-Allocation Tool",
  AUTO_ALLOCATE: "Auto-Allocate",
  ALLOCATION_PERCENTAGE: "Allocation Percentage",
  AMOUNT_TO_ALLOCATE: "Amount to Allocate",
  MINIMUM_ALLOCATION_VALIDATION_MESSAGE: (electionMinimum: number) =>
    `A minimum of ${getCurrencyFormattedValueWithZeroDefault(
      electionMinimum
    )} is required.`,
  DOWNLOAD_SIGNED_ELECTION_AGREEMENT: "Download Signed Election Agreement",
  REVISE_ELECTION: "Revise Election",
  PLUS_SIGN: "+",
  EQUAL_SIGN: "=",
  PIPE_SIGN: "|",
  PLEASE_NOTE: "Please Note: ",
  USER_EMAIL_ADDRESS: "User Email Address",
  USER_NAME: "User Name",
  TIME: "Time",
  ELECTED_AMOUNTS: "Elected Amounts",
  RESET_ADMIN_MODAL_BODY:
    "Are you sure you want to reset the election workflow? All inputs will be reset and you will be returned to Step 1.",
  RESET_ADMIN_MODAL_ACCEPT: "Yes",
  RESET_ADMIN_MODAL_DECLINE: "No",
  YOU_HAVE_UNSAVED_CHANGES:
    "You have unsaved changes. Would you like to save them before going back?",
  YOU_HAVE_UNSAVED_CHANGES_BA:
    "You have unsubmitted changes. Are you sure you want to go back? Any new accounts will not be saved.",
  YOU_HAVE_UNSAVED_CHANGES_ACCEPT: "Yes, Save My Changes",
  YOU_HAVE_UNSAVED_CHANGES_DECLINE: "No",
  YOU_HAVE_UNSAVED_CHANGES_ACCEPT_BA: "Yes, proceed without saving",
  YOU_HAVE_UNSAVED_CHANGES_DECLINE_BA: "No, stay in this page",
  REVISE_ELECTION_MESSAGE:
    "If you decide to revise your election, please note you will need to attest, review, sign & submit your election again before the deadline.",
  NOT_ABLE_RESUBMIT_AFTER_DEADLINE:
    "You will not be able to revise and re-submit your election once the election deadline has passed. ",
  SUBMISSION_DEADLINE_MESSAGE: (submissionDeadline: Date | undefined) =>
    `The deadline for this election is ${getFormattedDateTimeOnMDYOrNA(
      submissionDeadline
    )}.`,
  PLEASE_CONTACT: "If you have any questions, please contact ",
  DO_YOU_WANT_TO_REVISE: "Do you want to revise this election?",
  DO_YOU_WANT_TO_REMOVE: "Do you want to remove this election?",
  DIRECT_DEBIT_REQUIREMENT: "Direct Debit Requirement",
  DIRECT_DEBIT_CARD_TEXT:
    "Blackstone requires all participants in the Side-by-Side Program with a US domiciled USD bank account engage in direct debit for contributions. If you do not have a US domiciled bank account for contributions, you must provide one within 60 days. For those without US bank account, you must still provide distribution details which accepts USD.",
  DO_YOU_HAVE_US_BANK_ACCOUNT:
    "Do you have a United States domiciled bank account?",
  SUBMITTED_FILTER_LABEL: "Submitted",
  REOPENED_FILTER_LABEL: "Reopened",
  CURRENT_STAGE: "Current Stage",
  TAX_MESSAGE:
    "The tax team will reach out to you directly if any tax forms are required to be completed or updated. Please ensure compliance with any tax form requests to maintain participation in the program.",
  REOPEN_ELECTION_CONFIRMATION_MESSAGE: (
    mdmInvestmentVehicleId: number,
    mdmInvestmentVehicleName: string
  ) =>
    `Are you sure you want to re-open the election for ${mdmInvestmentVehicleName} (${mdmInvestmentVehicleId})?`,
  REMOVE_ELECTION_CONFIRMATION_MESSAGE: (
    mdmInvestmentVehicleId: number,
    mdmInvestmentVehicleName: string
  ) =>
    `Are you sure you want to remove ${mdmInvestmentVehicleName} (${mdmInvestmentVehicleId}) from the election? This will remove any existing submissions.`,
  REMOVE_ELECTION_ERRORED:
    "Error removing the election participant(s), please try again later.",
  REMOVE_ELECTION_SUCCEEDED: "Election participant(s) removed successfully.",
  REOPEN_WORKFLOW_ERRORED: "Error re-opening election, please try again later",
  REOPEN_WORKFLOW_SUCCESSFULLY: "Election successfully reopened.",
};

export const EntitySelectLabels = {
  DEFAULT_TEXT: "Select Client or Investment Vehicle to view specific data",
  ALL: "All",
};

export const ClientSelectLabels = {
  DEFAULT_TEXT: "Select Client to view specific data",
};

export const AdminClientLabels = {
  NO_RESULTS: (inputValue: string) =>
    `No results for "${inputValue}". Please make sure you are searching for the Client name or Id.`,
  SEARCH_PLACEHOLDER: "Search for client by name or entity Id",
  PLEASE_SEARCH: "Please search for a client to view data.",
  SEARCH_FOR: "Search for Client",
};

export const AdminEquityLabels = {
  NO_RESULTS: (inputValue: string) =>
    `No results for "${inputValue}". Please make sure you are searching for the Employee Id.`,
  SEARCH_PLACEHOLDER: "Search by Employee Id",
  PLEASE_SEARCH: "Please search for an employee to view data.",
  SEARCH_FOR: "Search for Employee",
};

export const ElectionAdminLabels = {
  ENTER_ROUND_ID: "Enter Election Round Id",
  DOWNLOAD_RESULTS: "Download Election Results",
  PLEASE_ENTER_ROUND_ID:
    "Please enter election round id to download election result.",
  SEARCH_FOR_ELECTOR: "Search for elector",
};

export const FiltersLabels = {
  FILTERS: "Filters",
  RESET_FILTERS: "Reset Filters",
  APPLY_FILTERS: "Apply Filters",
  APPLY_FILTER_WARNING: "Applying new filters will clear selected rows.",
  PLEASE_RESOLVE_ERRORS: "Please Resolve Errors",
};

export const UnrealizedValueLabels = {
  BUSINESS_UNITS: "Business Units",
  UNREALIZED_VALUE: "Unrealized Value",
  EXCLUDE_NEGATIVE_FUNDS:
    "Pie chart excludes strategies with negative unrealized value.",
  REMAINING_CAPITAL_INVESTED: "Remaining Invested",
  REMAINING_INVESTMENT: "Remaining Investment",
  GAIN_LOSS: "Gain/Loss",
  CARRY: "Carry",
  STRATEGY: "Strategy",
  UNREALIZED_TOTAL: "Unrealized Total",
  TOTAL: "Total",
  TOTAL_UNREALIZED_VALUE: "Total Unrealized Value",
  FUND: "Fund",
  ALL: "ALL",
  OTHER: "Other",
  OTHER_FUNDS: "Other Funds",
  CLICK_TO_VIEW_FUNDS: "Click to view funds",
  VIEW_UNDERLYING_FUNDS: "View underlying funds",
};

export const AgreementAcknowledgeLabels = {
  HEADER_LINE:
    "I acknowledge that by entering my signature and clicking the “I Agree” button below:",
  BULLET_LINE_1:
    "I. All of the information I have submitted in connection with this online election is true and accurate.",
  BULLET_LINE_2: "II. I agree to the anticipated funding requirements.",
  BULLET_LINE_3:
    "III. I have read, understand and agree with the information contained within the SBS Terms, SBS Intro Memo, Strategy Overview, Financing Options and LPA material provided.",
  BULLET_LINE_4:
    "IV. I agree to execute any amendments, waivers, consents, agreements or other documents requested by Blackstone in connection with the Side-by-Side (SBS) program.",
};

export const contatctEmail = "BXWealth.Support@Blackstone.com";

export const DisclosureLabels = {
  E_SIGN_DISCLOSURES: "E-Sign Disclosures",
  SUBTITLE: "Terms and Conditions regarding Electronic Disclosures",
  TERMS_LINE_1:
    "PLEASE READ THE FOLLOWING INFORMATION ABOUT RECEIVING ELECTRONICALLY SIGNED NOTICES, DISCLOSURES AND OTHER COMMUNICATIONS FROM BLACKSTONE REGARDING YOUR INVESTMENTS IN BLACKSTONE (COLLECTIVELY, “DISCLOSURES”) ELECTRONICALLY.",
  TERMS_LINE_2_1: `If you would prefer to receive disclosures in paper form, please contact us at 1(800) 262-8138 or `,
  TERMS_LINE_2_2: `. Paper copies will be provided to you at no charge.`,
  TERMS_LINE_3:
    "If you consent to receiving Disclosures from us electronically, your consent applies to any future Disclosures regarding your investments with Blackstone. However, we may, at our option, still mail any Disclosures in paper form.",
  TERMS_LINE_4:
    "To access and retain Disclosures electronically, you will need the following: a valid email address, Internet access, a printer or sufficient computer disk space to print or save copies of Disclosures and one of the latest two versions of an Internet browser such as:",
  CONTACT_INFORMATION:
    "You should keep us informed of any changes to your email or mailing address. You may update your contact information by contacting us at 1(800) 262-8138 or ",
  CHROME: "Chrome",
  FIREFOX: "Firefox",
  MICROSOFT_EDGE: "Microsoft Edge",
  SAFARI: "Safari",
  PHONE_NUMBER: "1(800) 262-8138",
};

export const CommitmentsLabels = {
  FORECASTED_FOOTNOTE:
    "This estimate is based on business deployment forecasts, which may change based on actual fund deployment.",
  COMMITMENTS_FOOTNOTE:
    "Only elections from the previous 5 years will be available to view.",
  AMOUNT: "Amount",
  CAPITAL_CALLED: "Capital Called",
  CAPITAL_CALLED_PERCENT: "Capital Called %",
  ELECTED_AMOUNT: "Elected Amount",
  FUND: "Fund",
  FUND_ID: "Fund Id",
  STRATEGY: "Strategy",
  TOTAL_FUTURE_CAP_CALLS: "Total Forecasted Future Capital Calls",
  ANNUAL_EXCEL_TAB: "Annual SBS Optional Commitments",
  LOF_EXCEL_TAB: "LOF Optional Commitments",
  COMMITMENT_YEAR: "Commitment Year",
};

export const CommitmentsTooltips = {
  FORECASTED_TOOLTIP: [
    "Estimate of your outstanding commitments which are forecasted to be called.",
    "Annual Optional and Mandatory Commitments: This estimate includes prior period investments which have closed but have not yet been called, updated current period estimates, and any known reserves. Note there may be additional follow-on investments that are not included in this estimate.",
    "Life of Fund Optional and Mandatory Commitments: This estimate includes your remaining full outstanding optional and mandatory life of fund commitments. This estimate does not include any recycled capital amounts.",
  ],
  LOF_ELECTED_AMNT_TOOLTIP: [
    "Final optional fund commitments made for a fund's full lifespan.  These amounts may have been adjusted by scale-back due to oversubscription and only include optional elections (not mandatory).",
  ],
  LOF_CAPITAL_CALLED_TOOLTIP: [
    "Total fund optional commitments that have been called from elected amounts.",
  ],
  ANNUAL_ELECTED_AMNT_TOOLTIP: [
    "Final optional annual commitments made in the referenced year across annual and interim elections (excluding any Life of Fund elections). The amounts may have been adjusted by scale-back and/or reallocations due to oversubscription.",
  ],
  ANNUAL_CAPITAL_CALLED_TOOLTIP: [
    "Total annual commitments that have been called from the elected amounts.",
  ],
};

export const BrowserLabels = {
  CHROME: "Chrome",
  EDGE: "Edge",
  FIREFOX: "Firefox",
  SAFARI: "Safari",
};

export const ElectorViewLabels = {
  INVESTMENT_VEHICLE_ID: "Investment Vehicle Id",
  INVESTMENT_VEHICLE_NAME: "Investment Vehicle Name",
  ELECTION_RESULTS: "Election Results",
  CURRENT_STEP: "Current Step",
  SUBMITTED_DATE: "Submitted Date",
  REOPENED_DATE: "Reopened Date",
  REOPEN: "Reopen",
  DELETE: "Delete",
  PERMISSIONED_USERS: "Permisisoned Users",
  MULTIPLE: "Multiple",
};

export const mapYesOrNo = (value: boolean) =>
  value ? ElectionsLabels.YES : ElectionsLabels.NO;

export const OptionalBooleanSelectorLabels = {
  YES: "Yes",
  NO: "No",
  ALL: "All",
};

export const DashboardTooltips = {
  UNREALIZED_CARRY: [
    "Unrealized value of carry compensation based on the quarter end valuations.",
  ],
  UNREALIZED_INVESTMENTS: [
    "Unrealized value of investments based on the quarter end valuations.",
  ],
};
