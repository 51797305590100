import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Stack,
  TextField,
} from "@mui/material";
import {
  areOptionalCookiesConfigured,
  areOptionalCookiesEnabled,
  DataLoadStatus,
  disableOptionalCookies,
  enableOptionalCookies,
  LoadingIndicator,
  useLogin,
  usePassiveCheck,
  useRefresh,
} from "common";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";

import { LoginFooter } from "../../common/components/LoginFooter/LoginFooter";
import { CookieNotification } from "../CookieNotification/CookieNotification";
import { LoginConstants } from "./constants";
import styles from "./Login.module.scss";

export const Login = () => {
  return (
    <div className={styles.loginPage}>
      <Card className={styles.authCard}>
        <CardHeader
          title={LoginConstants.TITLE}
          className={styles.authCardHeader}
          titleTypographyProps={{
            className: styles.authCardTitle,
          }}
        />
        <CardContent className={styles.authCardContent}>
          <AuthCard />
        </CardContent>
      </Card>
    </div>
  );
};

const AuthCard = () => {
  const auth = useAuth();
  const [username, setUsername] = useState(
    Cookies.get(LoginConstants.USERNAME_COOKIE) ?? ""
  );
  const [error, setError] = useState<string | null>(null);
  const [rememberMe, setRememberMe] = useState(
    Cookies.get(LoginConstants.USERNAME_COOKIE) !== undefined
  );
  const [showCookieNotification, setShowCookieNotification] = useState(false);

  const {
    initiateSeamlessSSO,
    isAuthInProgress,
    isLogout,
    logoutUser,
    navigateAwayFromLogin,
  } = useLogin();

  const initiateRefresh = useRefresh();

  const handleLogin = async () => {
    const trimmedUsername = username.trim();
    if (trimmedUsername.length === 0) {
      setError(LoginConstants.Error.EMPTY_USERNAME);
      return;
    }
    if (rememberMe && areOptionalCookiesEnabled()) {
      Cookies.set(LoginConstants.USERNAME_COOKIE, trimmedUsername, {
        expires: 365,
      });
    } else {
      Cookies.remove(LoginConstants.USERNAME_COOKIE);
    }
    await auth.signinRedirect({
      extraQueryParams: { login_hint: trimmedUsername },
    });
  };

  const passiveCheckStatus = usePassiveCheck();

  const acceptOptionalCookies = () => {
    enableOptionalCookies();
    setShowCookieNotification(false);
  };

  const declineOptionalCookies = () => {
    disableOptionalCookies();
    setShowCookieNotification(false);
  };

  useEffect(() => {
    const handleArrival = async () => {
      if (isLogout && auth.isAuthenticated) {
        logoutUser();
      } else if (auth.isAuthenticated) {
        navigateAwayFromLogin();
      } else if (auth.user?.refresh_token && !auth.activeNavigator) {
        initiateRefresh();
      } else if (passiveCheckStatus === DataLoadStatus.SUCCESSFUL) {
        // we can auto enable cookies for a user that hit the passive endpoint
        // because we know they are employees
        enableOptionalCookies();
        initiateSeamlessSSO();
      } else if (
        passiveCheckStatus === DataLoadStatus.UNSUCCESSFUL &&
        !areOptionalCookiesConfigured()
      ) {
        setShowCookieNotification(true);
      }
    };

    handleArrival();
  }, [
    auth.activeNavigator,
    auth.isAuthenticated,
    auth.isLoading,
    auth.user?.refresh_token,
    initiateRefresh,
    initiateSeamlessSSO,
    isLogout,
    logoutUser,
    navigateAwayFromLogin,
    passiveCheckStatus,
  ]);

  if (isAuthInProgress) {
    return <LoadingIndicator />;
  }

  return (
    <>
      {auth.error && (
        <Alert className={styles.alert} severity="error">
          We experienced an error while logging you in. Please try again.
        </Alert>
      )}
      <Box
        className={styles.loginForm}
        component="form"
        onSubmit={(e: React.FormEvent) => {
          e.preventDefault();
          handleLogin();
        }}
      >
        <TextField
          autoComplete={"off"}
          placeholder={LoginConstants.INPUT_PLACEHOLDER}
          onChange={(e) => setUsername(e.target.value)}
          value={username}
          error={error !== null}
          helperText={error}
          InputProps={{
            className: styles.loginInput,
          }}
        />
        <FormControlLabel
          checked={rememberMe}
          control={<Checkbox className={styles.checkbox} />}
          label={LoginConstants.REMEMBER_ME_LABEL}
          onChange={(_, checked) => setRememberMe(checked)}
        />
        <Stack className={styles.buttonBar}>
          <Button type="submit">{LoginConstants.NEXT_BUTTON_TEXT}</Button>
        </Stack>
      </Box>
      <LoginFooter
        className={styles.footerLinks}
        dividerClassName={styles.divider}
      />
      <CookieNotification
        open={showCookieNotification}
        onAcceptCookies={acceptOptionalCookies}
        onDeclineCookies={declineOptionalCookies}
      />
    </>
  );
};
