import { Document, Page, Text } from "@react-pdf/renderer";
import {
  IElectCardData,
  IElectionIVConfiguration,
  IElectionRoundConfiguration,
  IElectionWorkflowState,
} from "common";
import React from "react";

import { DisclosurePage } from "./DisclosurePage/DisclosurePage";
import { styles } from "./ElectionAgreementDocumentStyleSheet";
import { ElectionPage } from "./ElectionPage/ElectionPage";
import { EligibilityPage } from "./EligibilityPage/EligibilityPage";
import { LegalAttestationPage } from "./LegalAttestationPage/LegalAttestationPage";
import { SignSubmitPage } from "./SignSubmitPage/SignSubmitPage";

interface IElectionAgreementDocumentProps {
  workflowState: IElectionWorkflowState;
  roundConfig: IElectionRoundConfiguration;
  ivConfig: IElectionIVConfiguration;
  electionAmount: IElectCardData;
  isLegacyElection: boolean;
}

export const ElectionAgreementDocument = (
  props: IElectionAgreementDocumentProps
) => {
  const {
    workflowState,
    roundConfig,
    ivConfig,
    electionAmount,
    isLegacyElection,
  } = props;

  const ivName = `${ivConfig.investmentVehicle.name}`;

  return isLegacyElection ? (
    <Document>
      <Page size="A4" style={styles.page}>
        <ElectionPage
          electionAmount={electionAmount}
          canUseFinancing={ivConfig.canUseFinancing}
          useReallocation={workflowState.electionStages.elect.useReallocation}
          investmentVehicleName={ivName}
        />
      </Page>
    </Document>
  ) : (
    <Document>
      <Page size="A4" style={styles.page}>
        <LegalAttestationPage
          ivName={ivName}
          legalAttestation={roundConfig.stages.overview.legalAttestation}
          hasAcceptedLegalAttestation={
            workflowState.electionStages.overview.hasAcceptedLegalAttestation
          }
        />
        <EligibilityPage
          eligibilityStage={workflowState.electionStages.eligibility}
        />
        <ElectionPage
          electionAmount={electionAmount}
          canUseFinancing={ivConfig.canUseFinancing}
          useReallocation={workflowState.electionStages.elect.useReallocation}
        />
        <SignSubmitPage
          reviewAndSignStage={workflowState.electionStages.reviewAndSign}
        />
        <DisclosurePage />
        <Text style={styles.footer} fixed>
          {ivName}
        </Text>
      </Page>
    </Document>
  );
};
