import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  ElectionsLabels,
  ElectionStatus,
  ElectionWorkflowStageId,
  getCurrencyFormattedValueWithDashDefault,
  getCurrencyFormattedValueWithZeroDefault,
  IElectCardGridRow,
  isSomething,
  MonospaceNumber,
  MonospaceOptionalNumber,
  zeroIfNothing,
} from "common";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import {
  selectElectCardData,
  selectElectCardDataWithoutOptionalElection,
  selectElectCardGridTooltips,
  selectHasIVMoreThanOneStrategy,
  selectReallocationState,
} from "../../../../../redux/selectors";
import { selectCanUseFinancing } from "../../../../../redux/selectors";
import { IBaseStore } from "../../../../../redux/store";
import { ReviewAndSignFormQuestion } from "../ReviewAndSignFormQuestion/ReviewAndSignFormQuestion";
import styles from "./ElectionsCard.module.scss";
import { FinancingRows } from "./FinancingRows/FinancingRows";

interface IElectionsCardProps {
  electionStatus?: ElectionStatus;
  currentStage?: ElectionWorkflowStageId;
}

export const ElectionsCard = ({
  electionStatus,
  currentStage,
}: IElectionsCardProps) => {
  const hasForecastedInvestment = true;
  const electCardDataselector =
    electionStatus === ElectionStatus.CLOSED &&
    currentStage !== ElectionWorkflowStageId.COMPLETED
      ? selectElectCardDataWithoutOptionalElection
      : selectElectCardData;
  const electionData = useSelector(electCardDataselector);
  const tooltips = useSelector((state: IBaseStore) =>
    selectElectCardGridTooltips(state)
  );

  let nColsInTable = 4;
  nColsInTable += zeroIfNothing(electionData.totalRow.strategyMax) > 0 ? 1 : 0;
  nColsInTable += electionData.totalRow.remainingCommitment > 0 ? 1 : 0;
  nColsInTable += electionData.totalRow.mandatoryCommitment > 0 ? 1 : 0;

  const useReallocation = useSelector(selectReallocationState);
  const canUseFinancing = useSelector(selectCanUseFinancing);
  const hasIVMoreThanOneStrategy = useSelector((state: IBaseStore) =>
    selectHasIVMoreThanOneStrategy(state)
  );

  const mandatoryColHeader = useMemo(() => {
    return tooltips.mandatoryCommitmentYear > 0
      ? `${tooltips.mandatoryCommitmentYear} ${ElectionsLabels.MANDATORY_COMMITMENT}`
      : ElectionsLabels.MANDATORY_COMMITMENT;
  }, [tooltips.mandatoryCommitmentYear]);

  return (
    <Stack>
      <TableContainer id={styles.electionsOverviewTable}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{ElectionsLabels.STRATEGY}</TableCell>
              {isSomething(electionData.totalRow.strategyMax) &&
                electionData.totalRow.strategyMax.value > 0 && (
                  <TableCell align={"right"}>
                    {ElectionsLabels.STRATEGY_MAXIMUM}
                  </TableCell>
                )}
              {electionData.totalRow.remainingCommitment > 0 && (
                <TableCell align={"right"}>
                  {ElectionsLabels.ANTICIPATED_PRIOR_PERIODS_CAPITAL_CALLS}
                </TableCell>
              )}
              {electionData.totalRow.mandatoryCommitment > 0 && (
                <TableCell align={"right"}>
                  <Typography className={styles.textWithSign}>
                    <span className={styles.sign}>
                      {electionData.totalRow.remainingCommitment > 0 &&
                        ElectionsLabels.PLUS_SIGN}
                    </span>
                    {mandatoryColHeader}
                  </Typography>
                </TableCell>
              )}
              <TableCell align={"right"}>
                <Typography
                  id={styles.optionalHeader}
                  className={styles.textWithSign}
                >
                  <span className={styles.sign}>
                    {(electionData.totalRow.remainingCommitment > 0 ||
                      electionData.totalRow.mandatoryCommitment > 0) &&
                      ElectionsLabels.PLUS_SIGN}
                  </span>
                  {ElectionsLabels.OPT_ELECTION_HEADER_LINES(tooltips)[0]}
                  <br />
                  {ElectionsLabels.OPT_ELECTION_HEADER_LINES(tooltips)[1]}
                </Typography>
              </TableCell>
              <TableCell align={"right"}>
                <Typography className={styles.textWithSign}>
                  <span className={styles.sign}>
                    {(electionData.totalRow.remainingCommitment > 0 ||
                      electionData.totalRow.mandatoryCommitment > 0) &&
                      ElectionsLabels.EQUAL_SIGN}
                  </span>
                  {ElectionsLabels.TOTAL_COMMITMENT}
                </Typography>
              </TableCell>
              <TableCell align={"right"}>
                {ElectionsLabels.TOTAL_FORECASTED_INVESTMENT}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {electionData.electCardData.map(
              (electCardGridRow: IElectCardGridRow, idx: number) => {
                return (
                  <TableRow key={idx}>
                    <TableCell>{electCardGridRow.name}</TableCell>
                    {isSomething(electionData.totalRow.strategyMax) &&
                      electionData.totalRow.strategyMax.value > 0 && (
                        <TableCell align={"right"}>
                          <MonospaceOptionalNumber
                            value={electCardGridRow.strategyMax}
                            valueFormatter={
                              getCurrencyFormattedValueWithDashDefault
                            }
                          />
                        </TableCell>
                      )}
                    {electionData.totalRow.remainingCommitment > 0 && (
                      <TableCell align={"right"}>
                        <MonospaceNumber
                          value={electCardGridRow.remainingCommitment}
                          valueFormatter={
                            getCurrencyFormattedValueWithZeroDefault
                          }
                        />
                      </TableCell>
                    )}
                    {electionData.totalRow.mandatoryCommitment > 0 && (
                      <TableCell align={"right"}>
                        <MonospaceNumber
                          value={electCardGridRow.mandatoryCommitment}
                          valueFormatter={
                            getCurrencyFormattedValueWithZeroDefault
                          }
                        />
                      </TableCell>
                    )}
                    {isSomething(electionData.totalRow.optionalElection) && (
                      <TableCell align={"right"}>
                        <MonospaceNumber
                          value={zeroIfNothing(
                            electCardGridRow.optionalElection
                          )}
                          valueFormatter={
                            getCurrencyFormattedValueWithZeroDefault
                          }
                        />
                      </TableCell>
                    )}
                    <TableCell align={"right"}>
                      <MonospaceNumber
                        value={electCardGridRow.totalCommitment}
                        valueFormatter={
                          getCurrencyFormattedValueWithZeroDefault
                        }
                      />
                    </TableCell>
                    <TableCell align={"right"}>
                      <MonospaceNumber
                        value={electCardGridRow.totalForecastInvestment}
                        valueFormatter={
                          getCurrencyFormattedValueWithZeroDefault
                        }
                      />
                    </TableCell>
                  </TableRow>
                );
              }
            )}
          </TableBody>
          <TableFooter>
            <TableRow className={styles.total}>
              <TableCell>{electionData.totalRow.name}</TableCell>
              {isSomething(electionData.totalRow.strategyMax) &&
                electionData.totalRow.strategyMax.value > 0 && (
                  <TableCell align={"right"}>
                    <MonospaceNumber
                      value={electionData.totalRow.strategyMax.value}
                      valueFormatter={getCurrencyFormattedValueWithZeroDefault}
                    />
                  </TableCell>
                )}
              {electionData.totalRow.remainingCommitment > 0 && (
                <TableCell align={"right"}>
                  <MonospaceNumber
                    value={electionData.totalRow.remainingCommitment}
                    valueFormatter={getCurrencyFormattedValueWithZeroDefault}
                  />
                </TableCell>
              )}
              {electionData.totalRow.mandatoryCommitment > 0 && (
                <TableCell align={"right"}>
                  <MonospaceNumber
                    value={electionData.totalRow.mandatoryCommitment}
                    valueFormatter={getCurrencyFormattedValueWithZeroDefault}
                  />
                </TableCell>
              )}
              {isSomething(electionData.totalRow.optionalElection) && (
                <TableCell align={"right"}>
                  <MonospaceNumber
                    value={electionData.totalRow.optionalElection.value}
                    valueFormatter={getCurrencyFormattedValueWithZeroDefault}
                  />
                </TableCell>
              )}
              <TableCell align={"right"}>
                <MonospaceNumber
                  value={electionData.totalRow.totalCommitment}
                  valueFormatter={getCurrencyFormattedValueWithZeroDefault}
                />
              </TableCell>
              <TableCell align={"right"}>
                <MonospaceNumber
                  value={electionData.totalRow.totalForecastInvestment}
                  valueFormatter={getCurrencyFormattedValueWithZeroDefault}
                />
              </TableCell>
            </TableRow>
            {canUseFinancing && (
              <FinancingRows
                financing={electionData.financing}
                hasForecastedInvestment={hasForecastedInvestment}
                nColsInTable={nColsInTable}
              />
            )}
          </TableFooter>
        </Table>
      </TableContainer>
      {electionStatus !== ElectionStatus.CLOSED &&
        hasIVMoreThanOneStrategy &&
        isSomething(useReallocation) && (
          <ReviewAndSignFormQuestion
            title={ElectionsLabels.REALLOCATION}
            questionAnswerPairs={[
              {
                question: ElectionsLabels.REALLOCATION_QUESTION,
                answer: useReallocation.value
                  ? ElectionsLabels.YES
                  : ElectionsLabels.NO,
              },
            ]}
          />
        )}
    </Stack>
  );
};
