import {
  IGetElectionDetailsRequestPayload,
  isSomething,
  selectActiveElection,
  selectActiveElectionClientId,
  setActiveElection,
  setActiveElectionClient,
  some,
  WorkflowTypeEntity,
} from "common";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { InvestorUIStore } from "../../../redux/store";
import { useElectionQueryParams } from "./useElectionQueryParamsHook";

export const useActiveElection = () => {
  const dispatch = useDispatch();

  const { permittedElectionClients } = useSelector(
    (state: InvestorUIStore) => state.entitlements
  );

  const { investmentVehicleId, electionRoundId } = useElectionQueryParams();

  const activeElection = useSelector(selectActiveElection);

  const activeElectionClientId = useSelector(selectActiveElectionClientId);

  // This catches cases where we've refreshed the page or otherwise navigated
  // to the workflow without passing through the Elections List. We want to set
  // the active election client to whatever client corresponds to the current
  // workflow.
  useEffect(() => {
    const clientForCurrentElection = permittedElectionClients.find((client) => {
      return client.investmentVehicles.some(
        (iv) => iv.investmentVehicleId === investmentVehicleId
      );
    });
    if (
      clientForCurrentElection &&
      (!isSomething(activeElectionClientId) ||
        clientForCurrentElection.clientId !== activeElectionClientId.value)
    ) {
      dispatch(setActiveElectionClient(some(clientForCurrentElection)));
    }
  }, [
    activeElectionClientId,
    dispatch,
    investmentVehicleId,
    permittedElectionClients,
  ]);

  useEffect(() => {
    if (electionRoundId && investmentVehicleId) {
      const currentElection: IGetElectionDetailsRequestPayload = {
        electionRoundId,
        investmentVehicleId,
        workflowType: WorkflowTypeEntity.Investor,
      };

      if (
        !isSomething(activeElection) ||
        activeElection.value.electionRoundId !== electionRoundId ||
        activeElection.value.investmentVehicleId !== investmentVehicleId
      ) {
        dispatch(setActiveElection(currentElection));
      }
    }
  }, [dispatch, electionRoundId, investmentVehicleId, activeElection]);
};
