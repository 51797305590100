import {
  AuthenticatedPage,
  EnvironmentResolver,
  IBaseStore,
  IClientDto,
  IInvestmentVehicle,
  IInvestmentVehicleByClientByPeriod,
  isInProgress,
  isNotRequested,
  isSomething,
  LoadingIndicator,
  NoPermissionPage,
  nothing,
  Optional,
  PeriodHash,
  RelativePath,
  reqInternalInvestmentData,
  reqUserAgreements,
  selectCanViewCommitmentsPage,
  selectCanViewDashboard,
  selectCanViewInvestmentsAndCarry,
  some,
  useFetchDatasetIfIdDefined,
  useFetchEntitlements,
} from "common";
import React, { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import { PageName, Pages } from "../../../constants/Pages/Pages";
import { Login } from "../../../features/Authentication/Login";
import { SingleDocumentDownloadPageWrapper } from "../../../features/Documents/SingleDocumentDownloadPage/SingleDocumentDownloadPageWrapper";
import { InvestorUIStore } from "../../../redux/store";
import {
  canViewBankAccountPage,
  canViewDocumentsPage,
  canViewElections,
  canViewInvestmentDataPage,
  isPageVisible,
} from "../../utils/routingUtils";
import { UIErrorBoundary } from "../UIErrorBoundary/UIErrorBoundary";
import styles from "./RouterProvider.module.scss";

export const RouterProvider = () => {
  const auth = useAuth();
  const location = useLocation();
  const dispatch = useDispatch();

  const { userAgreements, userAgreementsLoadStatus } = useSelector(
    (state: InvestorUIStore) => state.userAgreementData
  );

  useEffect(() => {
    if (
      auth.isAuthenticated &&
      EnvironmentResolver.ENV.SHOW_USER_AGREEMENTS_FEATURE_FLAG &&
      isNotRequested(userAgreementsLoadStatus)
    ) {
      dispatch(reqUserAgreements());
    }
  }, [auth.isAuthenticated, dispatch, userAgreementsLoadStatus]);

  const showUserAgreements: boolean =
    EnvironmentResolver.ENV.SHOW_USER_AGREEMENTS_FEATURE_FLAG &&
    userAgreements.filter(
      (agreement) =>
        (agreement.isMandatory && agreement.hasAgreed !== true) ||
        agreement.hasAgreed === null
    ).length > 0;

  //Get the value of activeInternalInvestmentDataClientId, activeEquityEntitlement, isViewEquityEntitlementLoaded and isViewInvestorEntitlementsLoaded
  const {
    viewEquityEntitlements,
    bankAccountsEntitlements,
    bankAccountsEntitlementsLoadStatus,
    equityDataEntitlementLoadStatus,
    internalInvestmentEntitlementLoadStatus,
    viewInternalInvestmentDataPermittedEntities,
    documentClients,
    documentsEntitlementLoadStatus,
    electionsEntitlementLoadStatus,
    permittedElectionClients,
  } = useSelector((state: InvestorUIStore) => state.entitlements);

  const { selectedEntity } = useSelector(
    (state: InvestorUIStore) => state.viewData
  );

  const pageLoadStatus = useSelector(
    (state: InvestorUIStore) => state.viewData.pageLoadStatus
  );

  const internalInvestmentData = useSelector(
    (state: InvestorUIStore) => state.internalInvestmentData
  );

  const canViewClientDashboard = useSelector((store: IBaseStore) =>
    selectCanViewDashboard(store, true)
  );

  const canViewClientInvestmentsAndCarryPage = useSelector(
    (store: IBaseStore) => selectCanViewInvestmentsAndCarry(store, true)
  );

  const canViewCommitmentsPage = useSelector(selectCanViewCommitmentsPage);

  useFetchEntitlements(auth.user, [
    internalInvestmentEntitlementLoadStatus,
    equityDataEntitlementLoadStatus,
    documentsEntitlementLoadStatus,
    electionsEntitlementLoadStatus,
    bankAccountsEntitlementsLoadStatus,
  ]);

  const [dashboardInvestmentDataFilter, setDashboardInvestmentDataFilter] =
    useState<Optional<IInvestmentVehicleByClientByPeriod>>(nothing);

  useFetchDatasetIfIdDefined(
    reqInternalInvestmentData,
    dashboardInvestmentDataFilter,
    internalInvestmentData.internalInvestmentDataLoadStatus
  );

  useEffect(() => {
    if (isSomething(selectedEntity)) {
      if (
        isSomething(viewInternalInvestmentDataPermittedEntities) &&
        viewInternalInvestmentDataPermittedEntities.value.clients.length > 0
      ) {
        const matchingInvestmentClient =
          viewInternalInvestmentDataPermittedEntities.value.clients.find(
            (client: IClientDto) => client.id === selectedEntity.value.clientId
          );
        if (matchingInvestmentClient) {
          setDashboardInvestmentDataFilter(
            some({
              clientId: selectedEntity.value.clientId,
              periodId: PeriodHash.LATEST,
              investmentVehicleId: undefined,
              mdmClientId: undefined,
            })
          );
        }
      }
    } else {
      setDashboardInvestmentDataFilter(nothing);
    }
  }, [selectedEntity, viewInternalInvestmentDataPermittedEntities]);

  /*
  Set Dashboard Page if user have internal investment data entitlements.
  Set Equity Page if user have equity data entitlements.
  Set NoPermission Page if user have neither. 
  */

  const permissionsToCheck = [
    internalInvestmentEntitlementLoadStatus,
    equityDataEntitlementLoadStatus,
    documentsEntitlementLoadStatus,
    electionsEntitlementLoadStatus,
  ];

  if (EnvironmentResolver.ENV.SHOW_USER_AGREEMENTS_FEATURE_FLAG === true) {
    permissionsToCheck.push(userAgreementsLoadStatus);
  }

  const SetRouteByEntitlements = () => {
    if (isInProgress(...permissionsToCheck)) {
      return (
        <div className={styles.loadingIndicator}>
          <LoadingIndicator />
        </div>
      );
    }

    /* Need to check
    1. do we have permission to view internal investment entities
    2. have we selected an entity
    3. the selected entity we have internal investments permissions for
    4. has the internal investment data load is still in progress
    if all is true then wait for data to load to properly route user to correct page
    */

    if (
      isSomething(viewInternalInvestmentDataPermittedEntities) &&
      isSomething(selectedEntity) &&
      // check client that we have filtered over is one that we have permissions for
      viewInternalInvestmentDataPermittedEntities.value.clients.some(
        (client) =>
          client.id == selectedEntity.value.clientId &&
          (selectedEntity.value.investmentVehicleId === undefined ||
            client.investmentVehicles.some(
              (iv: IInvestmentVehicle) =>
                iv.id === selectedEntity.value.investmentVehicleId
            ))
      ) &&
      isInProgress(internalInvestmentData.internalInvestmentDataLoadStatus)
    ) {
      return (
        <div className={styles.loadingIndicator}>
          <LoadingIndicator />
        </div>
      );
    }
    const urlPath = location.pathname;
    const urlPathLower = urlPath.toLowerCase();
    if (
      urlPath !== urlPathLower &&
      Object.keys(Pages)
        .map((object) => Pages[object as PageName].path)
        .some((item) => item === urlPathLower)
    ) {
      /*
      if url string contains uppercase and corresponds to a valid page when made lowercase, navigate to the lowercase route.
        This forces all urls to automatically become lowercase, even if typed in capitals
      */
      return <Navigate to={urlPathLower} />;
    }

    // IMPORTANT: the order here determines which page user gets routed to by default!!
    return showUserAgreements ? (
      <Navigate to={Pages.UserAgreements.path} />
    ) : canViewInvestmentDataPage(
        canViewClientDashboard,
        selectedEntity,
        viewInternalInvestmentDataPermittedEntities
      ) ? (
      <Navigate to={Pages.Dashboard.path} />
    ) : canViewInvestmentDataPage(
        canViewClientInvestmentsAndCarryPage,
        selectedEntity,
        viewInternalInvestmentDataPermittedEntities
      ) ? (
      <Navigate to={Pages.InvestmentsandCarry.path} />
    ) : canViewCommitmentsPage ? (
      <Navigate to={Pages.Commitments.path} />
    ) : canViewElections(permittedElectionClients, selectedEntity) ? (
      <Navigate to={Pages.ElectionsList.path} />
    ) : viewEquityEntitlements.length > 0 ? (
      <Navigate to={Pages.Equity.path} />
    ) : canViewDocumentsPage(documentClients, selectedEntity) ? (
      <Navigate to={Pages.Documents.path} />
    ) : canViewBankAccountPage(
        bankAccountsEntitlements,
        bankAccountsEntitlementsLoadStatus,
        selectedEntity
      ) ? (
      <Navigate to={Pages.BankAccounts.path} />
    ) : (
      <NoPermissionPage />
    );
  };

  return (
    <div className={styles.page}>
      <Routes>
        <Route path={RelativePath.LOGIN} element={<Login />} />
        {Object.keys(Pages).map((pageKey) => {
          if (
            isPageVisible(
              pageKey as PageName,
              showUserAgreements,
              canViewClientDashboard,
              viewInternalInvestmentDataPermittedEntities,
              canViewClientInvestmentsAndCarryPage,
              viewEquityEntitlements,
              documentClients,
              canViewCommitmentsPage,
              permittedElectionClients,
              bankAccountsEntitlements,
              bankAccountsEntitlementsLoadStatus,
              selectedEntity
            )
          ) {
            const page = Pages[pageKey as PageName];
            return (
              <Route
                key={page.path}
                path={page.path}
                caseSensitive
                element={
                  <UIErrorBoundary page={page}>
                    <AuthenticatedPage
                      pageElement={page.element}
                      pageLoadStatus={pageLoadStatus}
                      hideFooter={page.name === Pages.UserAgreements.name}
                    />
                  </UIErrorBoundary>
                }
              />
            );
          }
        })}
        <Route
          path="documents/download"
          element={<SingleDocumentDownloadPageWrapper />}
        />
        <Route path={"*"} element={SetRouteByEntitlements()} />
      </Routes>
    </div>
  );
};
