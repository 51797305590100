import {
  IWriteUserAgreement,
  PathFragment,
  putUserAgreement,
  reqUserAgreements,
  useFetchDatasetWithoutId,
} from "common";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { LoginFooter } from "../../common/components/LoginFooter/LoginFooter";
import { AgreementCard } from "../../common/components/UserAgreement/UserAgreementCard";
import { InvestorUIStore } from "../../redux/store";
import { UserAgreementsConstants } from "./constants";
import styles from "./UserAgreements.module.scss";

export interface IUserAgreementsProps {
  redirectLink?: string;
}

export const UserAgreements = (props: IUserAgreementsProps) => {
  const { userAgreements, userAgreementsLoadStatus } = useSelector(
    (state: InvestorUIStore) => state.userAgreementData
  );

  const isTermsOfUse = window.location.pathname.includes(
    PathFragment.TERMS_OF_USE
  );
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [currentAgreementId, setCurrentAgreementId] = useState<number | null>(
    null
  );

  useFetchDatasetWithoutId(reqUserAgreements, userAgreementsLoadStatus);

  const agreement = useMemo(() => {
    if (isTermsOfUse) {
      return (
        userAgreements.find((a) => a.id === currentAgreementId) ||
        userAgreements[0]
      );
    }
    const unagreedAgreements = userAgreements.filter(
      (agreement) =>
        (agreement.isMandatory && agreement.hasAgreed !== true) ||
        agreement.hasAgreed === null
    );
    return (
      unagreedAgreements.find((a) => a.id === currentAgreementId) ||
      unagreedAgreements[0]
    );
  }, [userAgreements, currentAgreementId, isTermsOfUse]);

  useEffect(() => {
    if (agreement) {
      setCurrentAgreementId(agreement.id);
      document.title = agreement.name;
    }
  }, [agreement]);

  const handleNextAgreement = useCallback(() => {
    const nextIndex =
      userAgreements.findIndex((a) => a.id === currentAgreementId) + 1;
    if (nextIndex < userAgreements.length) {
      setCurrentAgreementId(userAgreements[nextIndex].id);
    } else {
      // Navigate to home when it's the last agreement
      navigate(props.redirectLink ?? "/");
    }
  }, [userAgreements, currentAgreementId, navigate, props.redirectLink]);

  const OnAgreeClick = useCallback(
    (agreementId: number) => {
      const writeUserAgreementPayload: IWriteUserAgreement = {
        agreementId: agreementId,
        hasAgreed: true,
      };
      dispatch(putUserAgreement(writeUserAgreementPayload));
    },
    [dispatch]
  );

  const { totalAgreements, currentAgreementIndex } = useMemo(() => {
    const total = userAgreements.length;
    if (isTermsOfUse) {
      const current =
        userAgreements.findIndex((a) => a.id === currentAgreementId) + 1;
      return {
        totalAgreements: total,
        currentAgreementIndex: current,
      };
    }
    const unsigned = userAgreements.filter(
      (agreement) =>
        (agreement.isMandatory && agreement.hasAgreed !== true) ||
        agreement.hasAgreed === null
    ).length;
    const current = total - unsigned + 1;
    return {
      totalAgreements: total,
      currentAgreementIndex: current,
    };
  }, [userAgreements, currentAgreementId, isTermsOfUse]);

  return (
    <div className={styles.userAgreements}>
      <AgreementCard
        agreement={agreement}
        currentAgreementIndex={currentAgreementIndex}
        totalAgreements={totalAgreements}
        onAgreeClick={isTermsOfUse ? handleNextAgreement : OnAgreeClick}
        buttonText={
          isTermsOfUse
            ? UserAgreementsConstants.NEXT_BUTTON_TEXT
            : UserAgreementsConstants.I_AGREE_BUTTON_TEXT
        }
      />
      <LoginFooter
        className={styles.footerLinks}
        dividerClassName={styles.divider}
        textClassName={styles.text}
      />
    </div>
  );
};
