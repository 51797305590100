import "ag-grid-enterprise";

import { Link, Typography } from "@mui/material";
import { ICellRendererParams } from "ag-grid-enterprise";
import { ApiPath, IDocument } from "common";
import React from "react";

import styles from "./DocumentNameGridCellRenderer.module.scss";

export interface IDocNameCellRendererParams
  extends ICellRendererParams<IDocument> {
  timeZoneQueryParams: string;
}

export const DocumentNameGridCellRenderer = (
  props: IDocNameCellRendererParams
) => {
  const { data, timeZoneQueryParams } = props;

  if (data) {
    return (
      <Typography variant="h5" className={styles.downloadWrapper}>
        <Link
          // display doc Id in bottom left of page on hover
          href={`${ApiPath.DOCUMENTS_URL_BASE}/documents/${data.documentOId}?${timeZoneQueryParams}`}
          className={styles.cellText}
          onClick={(e) => {
            // do not try to re-route to href, we handle this in DocumentGrid.tsx itself
            e.preventDefault();
          }}
        >
          {data.documentName}
        </Link>
      </Typography>
    );
  }
};
