import { Text, View } from "@react-pdf/renderer";
import {
  FinancingRowKeys,
  FinancingRowKeysToTitles,
  FinancingRowsOrder,
  getCurrencyFormattedValueWithZeroDefault,
  IElectCardFinancing,
} from "common";
import React from "react";

import { sharedStyles } from "../../SharedStyleSheet";
import { styles } from "./ElectionAmountTableFinancingRowsStyleSheet";

interface IElectionAmountTableFinancingRowsProps {
  financing: IElectCardFinancing;
}

export const ElectionAmountTableFinancingRows = (
  props: IElectionAmountTableFinancingRowsProps
) => {
  const { financing } = props;
  return (
    <>
      {FinancingRowsOrder.map((rowKey: FinancingRowKeys, index: number) => {
        // hide loan limit row if 0 value
        if (
          rowKey === FinancingRowKeys.LOAN_LIMIT &&
          financing.totalCommitment.loanLimit <= 0 &&
          financing.totalForecastedInvestment.loanLimit <= 0
        ) {
          return <></>;
        }

        const borderColor =
          rowKey === FinancingRowKeys.FINANCING
            ? sharedStyles.grayBorderBottom
            : {};

        return (
          <View style={styles.row} key={index}>
            <Text
              style={[sharedStyles.textColumn, styles.election, borderColor]}
            >
              {FinancingRowKeysToTitles[rowKey]}
            </Text>
            <Text
              style={[
                sharedStyles.textColumn,
                styles.totalCommitment,
                borderColor,
              ]}
            >
              {getCurrencyFormattedValueWithZeroDefault(
                financing.totalCommitment[rowKey]
              )}
            </Text>
            <Text
              style={[
                sharedStyles.textColumn,
                styles.forecastedInvestment,
                borderColor,
                sharedStyles.grayBackgroundColor,
              ]}
            >
              {getCurrencyFormattedValueWithZeroDefault(
                financing.totalForecastedInvestment[rowKey]
              )}
            </Text>
          </View>
        );
      })}
    </>
  );
};
