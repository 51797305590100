import {
  AccountCircle,
  CallMade,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Box,
  Container,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Popover,
  Tab,
  Tabs,
  Toolbar,
  Typography,
  useMediaQuery,
  useScrollTrigger,
} from "@mui/material";
import clsx from "clsx";
import {
  BreakpointConstants,
  DataLoadStatus,
  EnvironmentResolver,
  EquityLabel,
  IBaseStore,
  IPage,
  isNotRequested,
  PathFragment,
  RelativePath,
  reqAllEntitlements,
  reqUserAgreements,
  SearchParam,
  SectionHeader,
  selectCanViewCommitmentsPage,
  selectCanViewDashboard,
  selectCanViewInvestmentsAndCarry,
  StringConstants,
  useDoesCurrentPathMatch,
} from "common";
import React, { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { isPageVisible } from "../../common/utils/routingUtils";
import {
  NavbarDisplayOrder,
  NavTab,
  PageName,
  Pages,
} from "../../constants/Pages/Pages";
import { InvestorUIStore } from "../../redux/store";
import styles from "./Header.module.scss";

interface IMultiPageTabProps {
  navTab: NavTab;
  visiblePages: PageName[];
  currentRootPath: string;
  handlePageChange: (event: React.SyntheticEvent, newPath: string) => void;
}

/*
Component to render when we want to have sub tabs on 
  a navigation tab
*/
export const MultiPageTab = (props: IMultiPageTabProps) => {
  const { navTab, visiblePages, currentRootPath, handlePageChange } = props;

  const doesCurrentPathMatch = useDoesCurrentPathMatch();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick: React.MouseEventHandler = (
    event: React.MouseEvent<HTMLAnchorElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const isMobile = useMediaQuery(
    `(max-width:${BreakpointConstants.SMALL_MAX_WIDTH}px)`
  );

  const getTabIcon = () => {
    return anchorEl === null ? (
      <KeyboardArrowDown id={styles.dropdown} />
    ) : (
      <KeyboardArrowUp id={styles.dropdown} />
    );
  };

  return isMobile ? (
    <>
      <Tab
        className={clsx(
          styles.navTab,
          doesCurrentPathMatch(navTab.isActiveRegEx) && styles.parentTabSelected
        )}
        label={navTab.headerName}
        value={currentRootPath}
        onClick={handleClick}
      />
      {visiblePages.map((pageName: PageName) => {
        const page: IPage = Pages[pageName];
        return (
          <Tab
            className={clsx(
              styles.navTab,
              styles.subNavTab,
              doesCurrentPathMatch(page.path) && [
                styles.navTabSelected,
                styles.subNavTabSelected,
              ]
            )}
            label={page.name}
            key={page.path}
            value={page.rootPath ?? page.path}
            onClick={(event: React.SyntheticEvent) => {
              handlePageChange(event, page.path);
            }}
          />
        );
      })}
    </>
  ) : (
    <>
      <Tab
        className={clsx(
          styles.navTab,
          doesCurrentPathMatch(navTab.isActiveRegEx) && styles.navTabSelected
        )}
        label={navTab.headerName}
        value={currentRootPath}
        onClick={handleClick}
        icon={getTabIcon()}
        iconPosition={"end"}
      />
      <Menu
        key={navTab.headerName}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        disableScrollLock
      >
        {visiblePages.map((page: PageName, i: number) => {
          return (
            <MenuItem
              key={i}
              onClick={(event: React.SyntheticEvent) => {
                handlePageChange(event, Pages[page].path);
                handleClose();
              }}
              selected={doesCurrentPathMatch(Pages[page].path)}
            >
              {Pages[page].name}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

interface INavigationTabsProps {
  isVerticalTab: boolean;
  showUserAgreements: boolean;
  handlePageChange: (event: React.SyntheticEvent, newPath: string) => void;
}

export const NavigationTabs = (props: INavigationTabsProps) => {
  const { isVerticalTab, showUserAgreements, handlePageChange } = props;
  const dispatch = useDispatch();
  const location = useLocation();
  const doesCurrentPathMatch = useDoesCurrentPathMatch();

  const canViewClientDashboard = useSelector((store: IBaseStore) =>
    selectCanViewDashboard(store, true)
  );

  const canViewClientInvestmentsAndCarryPage = useSelector(
    (store: IBaseStore) => selectCanViewInvestmentsAndCarry(store, true)
  );

  const canViewCommitmentsPage: boolean = useSelector(
    selectCanViewCommitmentsPage
  );

  const {
    viewEquityEntitlements,
    equityDataEntitlementLoadStatus,
    internalInvestmentEntitlementLoadStatus,
    documentClients,
    documentsEntitlementLoadStatus,
    electionsEntitlementLoadStatus,
    permittedElectionClients,
    bankAccountsEntitlements,
    bankAccountsEntitlementsLoadStatus,
    viewInternalInvestmentDataPermittedEntities,
  } = useSelector((state: InvestorUIStore) => state.entitlements);

  const { selectedEntity } = useSelector(
    (state: InvestorUIStore) => state.viewData
  );

  // gets the root path of the url (i.e. /investments-and-carry for the url /investments-and-carry/overview)
  //  provided as value of tabs component so sub-page values are recognized as children of tabs component
  const [[, currentRoot]] = location.pathname.matchAll(/^(\/[^/]*)/g);

  useEffect(() => {
    if (
      internalInvestmentEntitlementLoadStatus ===
        DataLoadStatus.NOT_REQUESTED ||
      equityDataEntitlementLoadStatus === DataLoadStatus.NOT_REQUESTED ||
      documentsEntitlementLoadStatus === DataLoadStatus.NOT_REQUESTED ||
      electionsEntitlementLoadStatus === DataLoadStatus.NOT_REQUESTED
    ) {
      //Trigger a request to get the value of the investor data entitlement
      dispatch(reqAllEntitlements());
    }
  }, [
    internalInvestmentEntitlementLoadStatus,
    equityDataEntitlementLoadStatus,
    documentsEntitlementLoadStatus,
    electionsEntitlementLoadStatus,
    dispatch,
  ]);

  return (
    <Tabs
      orientation={isVerticalTab ? "vertical" : "horizontal"}
      id={styles.navTabs}
      value={currentRoot}
      //onChange={handlePageChange}
      TabIndicatorProps={{
        className: styles.indicator,
      }}
    >
      {NavbarDisplayOrder.map((navTab: NavTab) => {
        // check how many sub pages within the current nav tab are visible to user
        const visiblePages = navTab.subPages.filter((subPage: PageName) => {
          return isPageVisible(
            subPage,
            showUserAgreements,
            canViewClientDashboard,
            viewInternalInvestmentDataPermittedEntities,
            canViewClientInvestmentsAndCarryPage,
            viewEquityEntitlements,
            documentClients,
            canViewCommitmentsPage,
            permittedElectionClients,
            bankAccountsEntitlements,
            bankAccountsEntitlementsLoadStatus,
            selectedEntity
          );
        });
        if (visiblePages.length === 1) {
          // if only one page under tab, display normal tab
          const page = Pages[visiblePages[0]];
          const isActive = doesCurrentPathMatch(navTab.isActiveRegEx);
          const isDisabled = navTab.isDisabledRegEx
            ? doesCurrentPathMatch(navTab.isDisabledRegEx)
            : isActive;
          return (
            <Tab
              className={clsx(styles.navTab, isActive && styles.navTabSelected)}
              label={page.parentNavBarName ?? page.name}
              disabled={isDisabled}
              key={page.path}
              value={page.rootPath ?? page.path}
              onClick={(event: React.SyntheticEvent) => {
                handlePageChange(event, page.path);
              }}
            />
          );
        } else if (visiblePages.length > 1) {
          // if greater than one page under tab, display multi page tab
          return (
            <MultiPageTab
              key={navTab.headerName}
              navTab={navTab}
              visiblePages={visiblePages}
              currentRootPath={currentRoot}
              handlePageChange={handlePageChange}
            />
          );
        }
      })}
    </Tabs>
  );
};

export const Header = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const { userAgreements, userAgreementsLoadStatus } = useSelector(
    (state: InvestorUIStore) => state.userAgreementData
  );

  useEffect(() => {
    if (
      auth.isAuthenticated &&
      EnvironmentResolver.ENV.SHOW_USER_AGREEMENTS_FEATURE_FLAG &&
      isNotRequested(userAgreementsLoadStatus)
    ) {
      dispatch(reqUserAgreements());
    }
  }, [auth.isAuthenticated, dispatch, userAgreementsLoadStatus]);

  const showUserAgreements: boolean =
    EnvironmentResolver.ENV.SHOW_USER_AGREEMENTS_FEATURE_FLAG &&
    userAgreements.filter(
      (agreement) =>
        (agreement.isMandatory && agreement.hasAgreed !== true) ||
        agreement.hasAgreed === null
    ).length > 0;

  //Get the value of hasViewDocumentsEntitlement.
  const {
    viewEquityEntitlements,
    documentClients,
    bankAccountsEntitlements,
    bankAccountsEntitlementsLoadStatus,
    permittedElectionClients,
    viewInternalInvestmentDataPermittedEntities,
  } = useSelector((state: InvestorUIStore) => state.entitlements);

  const { selectedEntity } = useSelector(
    (state: InvestorUIStore) => state.viewData
  );

  const canViewClientDashboard = useSelector((store: IBaseStore) =>
    selectCanViewDashboard(store, true)
  );

  const canViewClientInvestmentsAndCarryPage = useSelector(
    (store: IBaseStore) => selectCanViewInvestmentsAndCarry(store, true)
  );

  const canViewCommitmentsPage: boolean = useSelector(
    selectCanViewCommitmentsPage
  );

  const [profileMenuAnchor, setProfileMenuAnchor] =
    useState<HTMLElement | null>(null);

  const [leftTabMenuAnchor, setLeftTabMenuAnchor] =
    useState<HTMLElement | null>(null);

  const leftNavOpen = Boolean(leftTabMenuAnchor);

  const handleLeftNavClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (leftNavOpen) {
      setLeftTabMenuAnchor(null);
    } else {
      setLeftTabMenuAnchor(event.currentTarget);
    }
  };

  const handleLeftNavClose = () => {
    setLeftTabMenuAnchor(null);
  };

  const setTabTitle = (path: string) => {
    const bxWealth = SectionHeader.BXWEALTH;

    if (path === RelativePath.HOME || path === `/${PathFragment.DASHBOARD}`) {
      document.title = bxWealth;
      return;
    }

    let pageKey: keyof typeof Pages;
    for (pageKey in Pages) {
      const page = Pages[pageKey];
      if (page.path !== path) {
        continue;
      }
      document.title = `${page.name} - ${bxWealth}`;
      return;
    }
  };

  useEffect(() => {
    setTabTitle(location.pathname);
  }, [location.pathname]);

  const resetToPage = (newPath: string) => {
    navigate(newPath);
    setTabTitle(newPath);
    setLeftTabMenuAnchor(null);
  };

  const handlePageChange = (event: React.SyntheticEvent, newPath: string) => {
    resetToPage(newPath);
  };

  const handleLogoClick = auth.isAuthenticated
    ? () => resetToPage(RelativePath.HOME)
    : undefined;

  const scrollTrigger = useScrollTrigger();

  /*
  Closes the logout popover on scroll
  */
  useEffect(() => {
    scrollTrigger && profileMenuAnchor !== null && setProfileMenuAnchor(null);
  }, [scrollTrigger, profileMenuAnchor, setProfileMenuAnchor]);

  return (
    <Container className={styles.navbar}>
      <Toolbar disableGutters className={styles.toolbar}>
        <Box
          id={styles.logoBox}
          className={auth.isAuthenticated ? styles.active : ""}
        >
          <Box
            id={styles.logo}
            component="img"
            alt={StringConstants.BLACKSTONE_GROUP}
            src="/assets/images/Blackstone_Logo.png"
            onClick={handleLogoClick}
          />
          {!showUserAgreements && location.pathname !== RelativePath.LOGIN && (
            <IconButton
              id={styles.tabIcon}
              size="large"
              onClick={handleLeftNavClick}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Popover
            id={styles.mobileDropdown}
            open={leftNavOpen}
            anchorEl={leftTabMenuAnchor}
            onClose={handleLeftNavClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            sx={{
              borderRadius: "0px",
            }}
          >
            <div className={styles.leftTabMenu}>
              <NavigationTabs
                isVerticalTab={true}
                showUserAgreements={showUserAgreements}
                handlePageChange={handlePageChange}
              />
              {/* Only display Merrill Lynch link out button if Equity tab is visible */}
              {isPageVisible(
                PageName.EQUITY,
                showUserAgreements,
                canViewClientDashboard,
                viewInternalInvestmentDataPermittedEntities,
                canViewClientInvestmentsAndCarryPage,
                viewEquityEntitlements,
                documentClients,
                canViewCommitmentsPage,
                permittedElectionClients,
                bankAccountsEntitlements,
                bankAccountsEntitlementsLoadStatus,
                selectedEntity
              ) && (
                <Link
                  underline="none"
                  className={styles.mlLink}
                  href={EquityLabel.MERRILL_LYNCH_BENEFITS_URL}
                  target="_blank"
                >
                  <div>
                    {EquityLabel.MERYLL_LYNCH}{" "}
                    <CallMade className={styles.arrowIcon} />
                  </div>
                </Link>
              )}
            </div>
          </Popover>
          {!showUserAgreements && location.pathname !== RelativePath.LOGIN && (
            <Typography
              noWrap
              id={styles.title}
              component="div"
              onClick={handleLogoClick}
            >
              BXWealth
            </Typography>
          )}
        </Box>
        {!showUserAgreements &&
          auth.isAuthenticated &&
          location.pathname !== RelativePath.LOGIN && (
            <div className={styles.toolbarMenu}>
              <Box className={styles.headerTabMenu}>
                <NavigationTabs
                  isVerticalTab={false}
                  showUserAgreements={showUserAgreements}
                  handlePageChange={handlePageChange}
                />
              </Box>
              <Box id={styles.profileBox}>
                <IconButton
                  id={styles.profileButton}
                  onClick={(e) => setProfileMenuAnchor(e.currentTarget)}
                >
                  <AccountCircle />
                </IconButton>
                <Menu
                  anchorEl={profileMenuAnchor}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={profileMenuAnchor !== null}
                  onClose={() => setProfileMenuAnchor(null)}
                  disableScrollLock={true}
                >
                  <MenuItem
                    className={styles.logout}
                    onClick={() => {
                      handleLeftNavClose();
                      navigate(
                        `${RelativePath.LOGIN}?${SearchParam.FROM_LOGOUT}=true`
                      );
                    }}
                  >
                    <div className={styles.logoutText}>
                      {StringConstants.LOGOUT}
                    </div>
                  </MenuItem>
                </Menu>
              </Box>
            </div>
          )}
      </Toolbar>
    </Container>
  );
};
