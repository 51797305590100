import {
  DataLoadStatus,
  Entity,
  IBankAccountPermittedClient,
  IBankAccountsEntitlements,
  IClientDto,
  IDocumentClients,
  IDocumentsClient,
  IInvestmentVehicle,
  IPermittedClient,
  IPermittedEntities,
  isSomething,
  Optional,
} from "common";

import { PageName } from "../../constants/Pages/Pages";

export const canViewInvestmentDataPage = (
  canClientViewPage: boolean,
  selectedEntity: Optional<Entity>,
  viewInternalInvestmentDataPermittedEntities: Optional<IPermittedEntities>
) => {
  return (
    canClientViewPage &&
    isSomething(selectedEntity) &&
    isSomething(viewInternalInvestmentDataPermittedEntities) &&
    viewInternalInvestmentDataPermittedEntities.value.clients.some(
      (client: IClientDto) =>
        selectedEntity.value.clientId === client.id &&
        (selectedEntity.value.investmentVehicleId === undefined ||
          client.investmentVehicles.some(
            (iv: IInvestmentVehicle) =>
              iv.id === selectedEntity.value.investmentVehicleId
          ))
    )
  );
};

export const canViewBankAccountPage = (
  bankAccountsEntitlements: IBankAccountsEntitlements,
  bankAccountsEntitlementsLoadStatus: DataLoadStatus,
  selectedEntity: Optional<Entity>
) => {
  if (bankAccountsEntitlementsLoadStatus != DataLoadStatus.SUCCESSFUL) {
    return false;
  }

  return (
    bankAccountsEntitlements.permittedBankAccountClients.flatMap(
      (client) => client.investmentVehicles
    ).length >= 1 &&
    bankAccountsEntitlements.permittedBankAccountClients.find(
      (client: IBankAccountPermittedClient) =>
        isSomething(selectedEntity) &&
        client.clientId === parseInt(selectedEntity.value.clientId)
    )
  );
};

export const canViewDocumentsPage = (
  documentsClients: Optional<IDocumentClients>,
  selectedEntity: Optional<Entity>
) => {
  return (
    isSomething(documentsClients) &&
    documentsClients.value.clients.length >= 1 &&
    documentsClients.value.clients.find(
      (client: IDocumentsClient) =>
        isSomething(selectedEntity) &&
        client.axiomId === selectedEntity.value.clientId
    )
  );
};

export const canViewElections = (
  electionsClients: IPermittedClient[],
  selectedEntity: Optional<Entity>
) => {
  return (
    electionsClients.length >= 0 &&
    electionsClients.find(
      (client: IPermittedClient) =>
        isSomething(selectedEntity) &&
        client.clientId.toString() === selectedEntity.value.clientId
    )
  );
};

export const isPageVisible = (
  pageName: PageName,
  showUserAgreements: boolean,
  canViewClientDashboardPage: boolean,
  viewInternalInvestmentDataPermittedEntities: Optional<IPermittedEntities>,
  canViewClientInvestmentsAndCarryPage: boolean,
  activeEquityDataEntitlements: string[],
  documentClients: Optional<IDocumentClients>,
  canViewCommitmentsPage: boolean,
  permittedElectionsClients: IPermittedClient[],
  bankAccountsEntitlements: IBankAccountsEntitlements,
  bankAccountsEntitlementsLoadStatus: DataLoadStatus,
  selectedEntity: Optional<Entity>
) => {
  const pageVisibility = {
    [PageName.USER_AGREEMENTS]: () => showUserAgreements,
    [PageName.BANK_ACCOUNTS]: () =>
      canViewBankAccountPage(
        bankAccountsEntitlements,
        bankAccountsEntitlementsLoadStatus,
        selectedEntity
      ),
    [PageName.INVESTMENTS_AND_CARRY]: () =>
      canViewInvestmentDataPage(
        canViewClientInvestmentsAndCarryPage,
        selectedEntity,
        viewInternalInvestmentDataPermittedEntities
      ),
    [PageName.DASHBOARD]: () =>
      canViewInvestmentDataPage(
        canViewClientDashboardPage,
        selectedEntity,
        viewInternalInvestmentDataPermittedEntities
      ),
    [PageName.COMMITMENTS]: () => canViewCommitmentsPage,
    [PageName.EQUITY]: () => activeEquityDataEntitlements.length > 0,
    [PageName.DOCUMENTS]: () =>
      canViewDocumentsPage(documentClients, selectedEntity),
    [PageName.ELECTION_WORKFLOW]: () =>
      canViewElections(permittedElectionsClients, selectedEntity),
    [PageName.ELECTIONS_LIST]: () =>
      canViewElections(permittedElectionsClients, selectedEntity),
    [PageName.REVIEW]: () =>
      canViewElections(permittedElectionsClients, selectedEntity),
    [PageName.TERMS_OF_USE]: () => true,
  };

  if (showUserAgreements) {
    return pageName === PageName.USER_AGREEMENTS;
  }

  return pageVisibility[pageName]();
};
