import { EntityDropdownStyle, isSomething } from "common";
import BaseEntityLabel from "common/src/components/BaseEntityLabel/BaseEntityLabel";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import { InvestorUIStore } from "../../../redux/store";
import { EntityDropdown } from "./EntityDropdown/EntityDropdown";

interface ISharedEntitySelectorProps {
  clientsOnly?: boolean;
  styleVariant?: EntityDropdownStyle;
}

export const SharedEntitySelector = ({
  clientsOnly = false,
  styleVariant,
}: ISharedEntitySelectorProps) => {
  const { allClients, selectedEntity } = useSelector(
    (state: InvestorUIStore) => state.viewData
  );

  const activeClient = useMemo(() => {
    if (!isSomething(selectedEntity)) return null;
    const activeClientId = selectedEntity.value.clientId;

    return allClients.find((c) => c.id === activeClientId);
  }, [selectedEntity, allClients]);

  // Logic to determine if the entity selector should be shown
  const shouldShowEntitySelector = useMemo(() => {
    const hasFirstClientMultipleIVs =
      allClients.length >= 1 && allClients[0].investmentVehicles.length > 1;

    if (allClients.length > 1 || (hasFirstClientMultipleIVs && !clientsOnly))
      return true;
  }, [allClients, clientsOnly]);

  return shouldShowEntitySelector ? (
    <EntityDropdown
      clients={allClients}
      clientsOnly={clientsOnly}
      styleVariant={styleVariant}
    />
  ) : (
    <BaseEntityLabel
      styleVariant={styleVariant}
      label={activeClient?.name ?? ""}
    />
  );
};
