import { Button, Card, Link, Stack, Typography } from "@mui/material";
import {
  DocumentsLabel,
  IBaseStore,
  IDocumentsClient,
  isInProgress,
  isSomething,
  nothing,
  Optional,
  PathFragment,
  some,
  StringConstants,
  useDownloadSingleDocument,
} from "common";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { InvestorUIStore } from "../../../redux/store";
import styles from "./SingleDocumentDownloadPage.module.scss";

interface DocumentIdentifier {
  type: string;
  value: string;
}

export const SingleDocumentDownloadPage = () => {
  const IDENTIFIER_SEPARATOR = "_";
  const { search } = useLocation();
  const [documentIdIsInvalid, setDocumentIdIsInvalid] = useState(false);

  const { selectedEntity } = useSelector((store: IBaseStore) => store.viewData);
  const {
    documentClients,
    internalInvestmentEntitlementLoadStatus,
    documentsEntitlementLoadStatus,
    equityDataEntitlementLoadStatus,
    electionsEntitlementLoadStatus,
  } = useSelector((store: InvestorUIStore) => store.entitlements);

  const { downloadSingleDocument } = useDownloadSingleDocument();

  // create a ref that can survive component being remounted and avoid downloading
  // the document twice if something weird happens
  const hasDownloaded = useRef(false);

  /*
  trigger page refresh to ensure page load is successful
  */
  const navigateToAllDocs = useCallback(() => {
    if (isSomething(selectedEntity) && isSomething(documentClients)) {
      const matchingClient = documentClients.value.clients.find(
        (docClient: IDocumentsClient) =>
          docClient.axiomId === selectedEntity.value.clientId
      );
      if (matchingClient) {
        window.location.href = `/${PathFragment.DOCUMENTS}`;
        return;
      }
    }
    window.location.href = `/`;
  }, [selectedEntity, documentClients]);

  const parseDocIdentifierFromUrl =
    useCallback((): Optional<DocumentIdentifier> => {
      const searchParams = new URLSearchParams(search);
      const documentIdentifier = searchParams.get("documentId");
      if (documentIdentifier) {
        const separatorIndex = documentIdentifier.indexOf(IDENTIFIER_SEPARATOR);
        if (separatorIndex > 0) {
          return some({
            type: documentIdentifier.substring(0, separatorIndex),
            value: documentIdentifier.substring(separatorIndex + 1),
          });
        }
      }

      return nothing;
    }, [search]);

  const handleDocDownload = useCallback(async () => {
    const identifier = parseDocIdentifierFromUrl();
    if (isSomething(identifier)) {
      switch (identifier.value.type) {
        case "oid":
          const responseCode = await downloadSingleDocument(
            Number(identifier.value.value)
          );
          if (responseCode >= 400) {
            setDocumentIdIsInvalid(true);
          }
          break;
        default:
          // If we don't recognize the identifer type show an error.
          setDocumentIdIsInvalid(true);
      }
    } else {
      setDocumentIdIsInvalid(true);
    }
  }, [downloadSingleDocument, parseDocIdentifierFromUrl]);

  useEffect(() => {
    // wait until entitlements are loaded to download document
    if (
      hasDownloaded.current === false &&
      !isInProgress(
        internalInvestmentEntitlementLoadStatus,
        documentsEntitlementLoadStatus,
        equityDataEntitlementLoadStatus,
        electionsEntitlementLoadStatus
      )
    ) {
      hasDownloaded.current = true;
      handleDocDownload();
    }
  }, [
    handleDocDownload,
    internalInvestmentEntitlementLoadStatus,
    documentsEntitlementLoadStatus,
    equityDataEntitlementLoadStatus,
    electionsEntitlementLoadStatus,
  ]);

  return (
    <Stack id={styles.content}>
      <Button onClick={navigateToAllDocs}>
        {DocumentsLabel.RETURN_TO_ALL_DOCUMENTS}
      </Button>
      <Card id={styles.messageCard}>
        {documentIdIsInvalid ? (
          <>
            <Typography>{DocumentsLabel.INACCESIBLE_DOCUMENT}</Typography>
            <Typography>
              {DocumentsLabel.PLEASE}
              <Link onClick={navigateToAllDocs}>
                {DocumentsLabel.CLICK_HERE}
              </Link>
              {DocumentsLabel.TO_RETURN_TO_PLATFORM}
            </Typography>
          </>
        ) : (
          <>
            <Typography>{DocumentsLabel.DOWNLOADING_YOUR_DOCUMENT}</Typography>
            <Typography>
              {DocumentsLabel.PLEASE}
              <Link onClick={handleDocDownload}>
                {DocumentsLabel.CLICK_HERE}
              </Link>
              {DocumentsLabel.IF_YOUR_DOCUMENT_DOES_NOT_DOWNLOAD}
            </Typography>
          </>
        )}
        <Typography>
          {DocumentsLabel.IF_YOU_EXPERIENCE_ISSUES}
          <Link href={StringConstants.MAIL_TO_BXWEALTH_SUPPORT_EMAIL}>
            {StringConstants.BXWEALTH_SUPPORT_EMAIL}
          </Link>
          {DocumentsLabel.FOR_ASSISTANCE}
        </Typography>
      </Card>
    </Stack>
  );
};
