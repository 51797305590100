import { Stack, Typography } from "@mui/material";
import { GridReadyEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import {
  ElectionsLabels,
  FailedToLoadError,
  IElectionsForClientListItem,
  isInProgress,
  isSomething,
  isUnsuccessful,
  LoadingIndicator,
  reqElectionsForClient,
  selectActiveElectionClient,
  selectSortedElections,
  setActiveElectionClient,
  some,
  useFetchDatasetIfIdDefined,
  useGridExtensions,
} from "common";
import React, { useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { SharedEntitySelector } from "../../../common/components/SharedEntitySelector/SharedEntitySelector";
import { InvestorUIStore } from "../../../redux/store";
import styles from "./ElectionsList.module.scss";
import { useElectionList } from "./Hooks/useElectionList";
import { NoElectionsMessage } from "./NoElectionsMessage/NoElectionsMessage";

export const ElectionsList = () => {
  const gridRef = useRef<AgGridReact<IElectionsForClientListItem>>(null);
  const { setHeaderHeight, resizeColumns, onGridReady } = useGridExtensions();
  const { colDefs, defaultColDef } = useElectionList();

  const dispatch = useDispatch();

  const { sortedElections, electionsForClientLoadStatus } = useSelector(
    (state: InvestorUIStore) => selectSortedElections(state)
  );

  const activeElectionClient = useSelector(selectActiveElectionClient);

  const permittedElectionClients = useSelector((store: InvestorUIStore) =>
    [...store.entitlements.permittedElectionClients].sort((client1, client2) =>
      client1.clientName.localeCompare(client2.clientName)
    )
  );

  const retryFunction = useFetchDatasetIfIdDefined(
    reqElectionsForClient,
    activeElectionClient,
    electionsForClientLoadStatus
  );

  const overlayComponent = useMemo(() => {
    return isUnsuccessful(electionsForClientLoadStatus)
      ? () => <FailedToLoadError retryRequest={retryFunction} />
      : NoElectionsMessage;
  }, [electionsForClientLoadStatus, retryFunction]);

  useEffect(() => {
    if (!isSomething(activeElectionClient) && permittedElectionClients.length) {
      dispatch(setActiveElectionClient(some(permittedElectionClients[0])));
    }
  });

  return (
    <div className={styles.page}>
      <div className={styles.pageHeader}>
        <SharedEntitySelector clientsOnly={true} />
        <Typography variant="h1">{ElectionsLabels.ELECTIONS}</Typography>
      </div>
      <div className={styles.pageContent}>
        <Stack className="ag-theme-alpine" id={styles.dataGrid}>
          <AgGridReact<IElectionsForClientListItem>
            loading={isInProgress(electionsForClientLoadStatus)}
            ref={gridRef}
            rowData={sortedElections}
            domLayout="autoHeight"
            defaultColDef={defaultColDef}
            columnDefs={colDefs}
            onFirstDataRendered={setHeaderHeight}
            onColumnResized={setHeaderHeight}
            cacheQuickFilter={true}
            suppressAggFuncInHeader={true}
            suppressContextMenu={true}
            suppressCellFocus={true}
            onRowDataUpdated={resizeColumns}
            onGridSizeChanged={resizeColumns}
            onDisplayedColumnsChanged={resizeColumns}
            onModelUpdated={resizeColumns}
            suppressMenuHide={true}
            enableCellTextSelection={true}
            onGridReady={(params: GridReadyEvent) => {
              onGridReady(params);
            }}
            noRowsOverlayComponent={overlayComponent}
            scrollbarWidth={10}
            suppressDragLeaveHidesColumns={true}
            suppressMovableColumns={true}
            tooltipShowDelay={0}
            loadingOverlayComponent={LoadingIndicator}
          />
        </Stack>
        <div className={styles.pageFooter}>
          <p>{ElectionsLabels.FOOTENOTE_LINE1}</p>
          <p>{ElectionsLabels.FOOTENOTE_LINE2}</p>
        </div>
      </div>
    </div>
  );
};
