import { Page, SectionHeader } from "common";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { SharedEntitySelector } from "../../common/components/SharedEntitySelector/SharedEntitySelector";
import { DocumentsGrid } from "./DocumentsGridPage/DocumentsGrid/DocumentsGrid";

export const Documents = () => {
  return (
    <Routes>
      <Route
        path=""
        element={
          <Page
            pageMenu={<SharedEntitySelector clientsOnly={true} />}
            pageHeader={SectionHeader.DOCUMENTS}
            content={<DocumentsGrid />}
          />
        }
      />
      {/* if someone goes to no a non-sense URL reroute them to the main doc page */}
      <Route path="*" element={<Navigate to="." replace />} />
    </Routes>
  );
};
