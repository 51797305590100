import {
  FailedToLoadError,
  IBankAccountPermittedClient,
  IBaseStore,
  isSomething,
  isUnsuccessful,
  reqBankAccountCountries,
  reqBankAccountCurrencies,
  reqBankAccountsForClient,
  useFetchDatasetIfIdDefined,
  useFetchDatasetWithoutId,
} from "common";
import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { InvestorUIStore } from "../../redux/store";
import { AssignBankAccountsCard } from "./AssignBankAccountsCard/AssignBankAccountsCard";
import styles from "./BankAccounts.module.scss";
import { BankAccountsCard } from "./BankAccountsCard/BankAccountsCard";
import { NoBankAccountsMessage } from "./NoBankAccountsMessage/NoBankAccountsMessage";

export const BankAccounts = () => {
  const { selectedEntity } = useSelector(
    (state: InvestorUIStore) => state.viewData
  );

  const { bankAccountsLoadStatus, countriesLoadStatus, currenciesLoadStatus } =
    useSelector((state: IBaseStore) => state.bankAccounts);

  const retryFunction = useFetchDatasetIfIdDefined(
    reqBankAccountsForClient,
    selectedEntity,
    bankAccountsLoadStatus
  );

  useFetchDatasetWithoutId(reqBankAccountCountries, countriesLoadStatus);
  useFetchDatasetWithoutId(reqBankAccountCurrencies, currenciesLoadStatus);

  const navigate = useNavigate();

  const overlayComponent = useMemo(() => {
    return isUnsuccessful(bankAccountsLoadStatus)
      ? () => <FailedToLoadError retryRequest={retryFunction} />
      : NoBankAccountsMessage;
  }, [bankAccountsLoadStatus, retryFunction]);

  const {
    bankAccountsEntitlements: { permittedBankAccountClients },
  } = useSelector((store: InvestorUIStore) => store.entitlements);

  useEffect(() => {
    if (isSomething(selectedEntity)) {
      // check for selected entity in bank account entitlements
      const matchingClient = permittedBankAccountClients.find(
        (client: IBankAccountPermittedClient) =>
          client.clientId === parseInt(selectedEntity.value.clientId)
      );
      // if no match, leave bank accounts page
      if (!matchingClient) {
        navigate("/");
      }
    }
  });

  return (
    <div className={styles.pageContent}>
      <AssignBankAccountsCard retryFunction={retryFunction} />
      <BankAccountsCard noRowsOverlayComponent={overlayComponent} />
    </div>
  );
};
