import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS

import {
  ColumnStates,
  copyColumnStates,
  createDocumentRequest,
  deselectAllDocuments,
  FilterButton,
  getNumFiltersApplied,
  isSomething,
  PossibleDocumentColumns,
  reqDocuments,
  resetAllColumnFilterValues,
  updateDocumentColumnSettings,
} from "common";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { InvestorUIStore } from "../../../../redux/store";
import { DocumentsFilterBody } from "./DocumentsFilterBody/DocumentsFilterBody";

export interface IDocumentsGridFilterProps {
  disabled: boolean;
}

export const DocumentsGridFilter = ({
  disabled,
}: IDocumentsGridFilterProps) => {
  const dispatch = useDispatch();

  const { columnStates, selectedDocuments, pageSize, searchTerm, sortOptions } =
    useSelector((store: InvestorUIStore) => store.documentSettings);
  const { selectedEntity } = useSelector(
    (store: InvestorUIStore) => store.viewData
  );
  // Store a copy of the current active filters
  const originalColumns = useMemo(() => {
    const copy = copyColumnStates(columnStates);
    return copy;
  }, [columnStates]);

  // initialize current selections based on the original filters
  const [currentSelections, setCurrentSelections] =
    useState<ColumnStates>(originalColumns);

  useEffect(() => {
    setCurrentSelections(originalColumns);
  }, [originalColumns]);

  const countActiveFilters = useMemo(() => {
    return getNumFiltersApplied(columnStates);
  }, [columnStates]);

  const fetchDocuments = useCallback(
    (columnStates: ColumnStates) => {
      const request = createDocumentRequest(
        1,
        pageSize,
        columnStates,
        searchTerm,
        sortOptions,
        selectedEntity
      );
      if (isSomething(request)) {
        dispatch(reqDocuments(request.value));
      }
    },
    [dispatch, pageSize, searchTerm, sortOptions, selectedEntity]
  );

  const hasErrors = useMemo(() => {
    return PossibleDocumentColumns.some(
      (column) => currentSelections[column].filter.errors.length > 0
    );
  }, [currentSelections]);

  const handleApplyFilters = () => {
    dispatch(updateDocumentColumnSettings(currentSelections));
    dispatch(deselectAllDocuments());
    fetchDocuments(currentSelections);
  };
  const handleResetFilters = () => {
    const newSelections = copyColumnStates(currentSelections);
    resetAllColumnFilterValues(newSelections);
    setCurrentSelections(newSelections);
    dispatch(updateDocumentColumnSettings(newSelections));
    fetchDocuments(newSelections);
  };

  const hasWarnings = Object.keys(selectedDocuments).length > 0;

  return (
    <FilterButton
      disabled={disabled}
      countActiveFilters={countActiveFilters}
      handleApplyFilters={handleApplyFilters}
      handleResetFilters={handleResetFilters}
      hasErrors={hasErrors}
      hasWarnings={hasWarnings}
    >
      <DocumentsFilterBody
        columnStates={columnStates}
        currentSelections={currentSelections}
        onCurrentSelectionChange={setCurrentSelections}
      />
    </FilterButton>
  );
};
