import { PayloadAction } from "@reduxjs/toolkit";
import { call, put } from "redux-saga/effects";

import { DataLoadStatus } from "../../constants/enums";
import {
  DocumentRequest,
  DocumentServerResponse,
  getDocumentFilters,
  getDocumentsForClient,
  IDocumentFiltersSource,
} from "../../services/documentsService";
import {
  IDocument,
  IDocumentsClient,
  IDocumentType,
  IDocumentTypeCategory,
  IEntity,
} from "../../types/dataTypes";
import { EMPTY_DOCUMENT_RESPONSE } from "../../types/defaultTypes";
import { DocumentColumnFilterOptions } from "../../types/documentSettingTypes";
import { Json, LoadError, Maybe, Optional, some } from "../../types/typeUtils";
import {
  convertToDateObject,
  convertToShortDateObject,
} from "../../utils/converters";
import {
  errAvailableDocumentFilters,
  errDocuments,
  recvDocuments,
  updateDocumentAvailableFilterConfig,
} from "../actions/documentActions";

export function* fetchAvailableDocumentFilters(
  action: PayloadAction<IDocumentsClient>
) {
  try {
    const availableDocumentFilters: Maybe<IDocumentFiltersSource> = yield call(
      getDocumentFilters,
      action.payload.oId
    );

    if (availableDocumentFilters === undefined) {
      return;
    } else {
      /*
      helper function to de-duplicate list of document type categories
      */
      const deDupDocTypeCategory = (
        docTypeCategories: IDocumentTypeCategory[]
      ): Optional<IDocumentTypeCategory>[] => {
        return [
          ...new Map(
            docTypeCategories.map((docTypeCategory) => [
              docTypeCategory.id,
              some(docTypeCategory),
            ])
          ).values(),
        ];
      };

      /*
      helper function to convert json response object into DocumentColumnFilterOptions object
      */
      const convert = (
        docFilters: IDocumentFiltersSource
      ): DocumentColumnFilterOptions => {
        return {
          documentType: docFilters.documentTypes.map(
            (docType: Json<IDocumentType>) => {
              return some({
                name: docType.name,
                id: docType.id,
                category: {
                  name: docType.category.name,
                  id: docType.category.id,
                },
              });
            }
          ),
          documentTypeCategory: deDupDocTypeCategory(
            docFilters.documentTypes.map((docType: Json<IDocumentType>) => {
              return {
                name: docType.category.name,
                id: docType.category.id,
              };
            })
          ),
          period: docFilters.periods.map((value) => some(value)),
          investmentVehicles: docFilters.investmentVehicles.map(
            (docIV: Json<IEntity>) => {
              return some({
                name: docIV.name,
                oId: docIV.oId,
              });
            }
          ),
          funds: docFilters.funds.map((docFund: Json<IEntity>) => {
            return some({
              name: docFund.name,
              oId: docFund.oId,
            });
          }),
          taxDocumentStatus: docFilters.taxDocumentStatuses.map((value) =>
            some(value)
          ),
          partnerships: docFilters.partnerships.map(
            (docPartnership: Json<IEntity>) => {
              return some({
                name: docPartnership.name,
                oId: docPartnership.oId,
                ein: docPartnership.ein,
              });
            }
          ),
        };
      };

      const convertedDocFilters = convert(availableDocumentFilters);
      yield put(updateDocumentAvailableFilterConfig(convertedDocFilters));
    }
  } catch (e) {
    if (e instanceof LoadError) {
      // TODO: may want to revisit this if we get new error logic
      yield put(errAvailableDocumentFilters(DataLoadStatus.EMPTY_RESPONSE));
    }
  }
}

const parseAndSortEntityList = (entityList: Json<IEntity>[]): IEntity[] => {
  return entityList
    .map((entity: Json<IEntity>) => {
      return {
        name: entity.name,
        oId: entity.oId,
        ein: entity.ein,
      };
    })
    .sort((entityA: IEntity, entityB: IEntity) => {
      return entityA.name < entityB.name ? -1 : 1;
    });
};

const convertDocument = (serverDocument: Json<IDocument>): IDocument => {
  return {
    documentOId: serverDocument.documentOId,
    documentName: serverDocument.documentName,
    documentType: {
      name: serverDocument.documentType.name,
      id: serverDocument.documentType.id,
      category: {
        name: serverDocument.documentType.category.name,
        id: serverDocument.documentType.category.id,
      },
    },
    period: serverDocument.period,
    funds: parseAndSortEntityList(serverDocument.funds),
    publishedDate: convertToShortDateObject(serverDocument.publishedDate),
    investmentVehicles: parseAndSortEntityList(
      serverDocument.investmentVehicles
    ),
    taxDocumentStatus: serverDocument.taxDocumentStatus,
    jurisdictions: serverDocument.jurisdictions.sort(),
    partnerships: parseAndSortEntityList(serverDocument.partnerships),
    effectiveDate: serverDocument.effectiveDate
      ? convertToShortDateObject(serverDocument.effectiveDate)
      : null,
    downloadedDate: serverDocument.downloadedDate
      ? convertToDateObject(serverDocument.downloadedDate)
      : null,
  };
};

export function* fetchDocuments(action: PayloadAction<DocumentRequest>) {
  try {
    const documentsResponse: Maybe<DocumentServerResponse> = yield call(
      getDocumentsForClient,
      action.payload
    );

    if (documentsResponse === undefined) {
      put(recvDocuments(EMPTY_DOCUMENT_RESPONSE));
    } else {
      yield put(
        recvDocuments({
          ...documentsResponse,
          documents: documentsResponse.documents.map(convertDocument),
        })
      );
    }
  } catch (e) {
    if (e instanceof LoadError) {
      yield put(errDocuments());
    }
  }
}
