import clsx from "clsx";
import { EntityDropdownStyle } from "common";
import React from "react";

import styles from "./BaseEntityLabel.module.scss";

type BaseEntityLabelProps = {
  label: string;
  styleVariant?: EntityDropdownStyle;
};

const BaseEntityLabel = ({ label, styleVariant }: BaseEntityLabelProps) => {
  return (
    <span
      className={clsx(styles.EntityLabel, {
        [styles.DashboardVariant]:
          styleVariant === EntityDropdownStyle.DASHBOARD,
      })}
    >
      {label}
    </span>
  );
};

export default BaseEntityLabel;
